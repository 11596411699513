import { createTheme } from '@mui/material/styles';


const fontSize_H5 = {
    xs: '20px',
    sm: '20px',
    md: '25px',
    lg: '30px',
    xl: '30px',
};

const fontSize_P = {
    xs: '15px',
    sm: '15px',
    md: '18px',
    lg: '20px',
    xl: '20px',
};


export const theme_colegios_ingresados = createTheme({
    palette: {
        primary: {
            main: '#ff5b00',
        },
        secondary: {
            main: "#ff5b00",
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                },

                h5: {
                    textAlign: 'center',
                    fontSize: fontSize_H5,
                    marginTop: '1rem',
                    color: 'black',
                    fontWeight: 'bold',
                    marginBottom:'20px',
                },
                body1: {
                    fontSize: fontSize_P,
                    textAlign: "justify",
                    color: '#706F6F',
                    marginBottom:'20px',

                }
            }
        },



    }
});
