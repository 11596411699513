import React, { useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid, Hidden, ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import { theme_navbar } from '../../components/Navbar/theme_navbar';
import logo from "../../assets/images/logo.png"
/* import { Link } from 'react-router-dom'; */
import khrono_latam_logo from '../../assets/img/LOGO_KHRONO_1.png';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MobileMenuDos from "./MobileMenuDos";


export default function Navbar() {
    const { isAuthenticated, logout } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
    };


    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElContacto, setAnchorElContacto] = useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const theme = useTheme();
    const isXsOrSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [productsAnchorEl, setProductsAnchorEl] = React.useState(null);
    const [contactosAnchorEl, setContactosAnchorEl] = React.useState(null);


    const direccionCorreo = 'contacto@khronolatam.cl';
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClickContacto = (event) => {
        setAnchorElContacto(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuCloseContacto = () => {
        setAnchorElContacto(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseContacto = () => {
        setAnchorElContacto(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickContacto = (event) => {
        setAnchorElContacto(event.currentTarget);
    };

    const handleSubMenuClick = (event) => {
        setSubMenuAnchorEl(event.currentTarget);
    };

    const handleSubMenuClose = () => {
        setSubMenuAnchorEl(null);
    };

    const handleProductsMenuOpen = (event) => {
        setProductsAnchorEl(event.currentTarget);
    };

    const handleContactosMenuOpen = (event) => {
        setContactosAnchorEl(event.currentTarget);
    };


    const handleProductsMenuClose = () => {
        setProductsAnchorEl(null);
    };

    const handleContactosMenuClose = () => {
        setContactosAnchorEl(null);
    };

    const MobileMenu = () => (
        <Menu
            id="menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            {/*  <MenuItem onClick={handleMenuClose}>
                <Link to="/blog" style={{ textDecoration: 'none', color: '#706F6F' }}>
                    Blog
                </Link>
            </MenuItem> */}
            {/*  <MenuItem onClick={handleMenuClose}>
                <Link to="/contacto" style={{ textDecoration: 'none', color: '#706F6F' }}>
                    Contacto
                </Link>
            </MenuItem> */}

            <MenuItem>
                <a
                    aria-controls="productos-menu"
                    aria-haspopup="true"
                    onClick={handleContactosMenuOpen}
                    style={{
                        color: '#706F6F',
                        display: 'inline-flex', // Añadir display inline-flex
                        alignItems: 'center',  // Asegurar alineación vertical
                        textDecoration: 'none' // Opcional, para remover el subrayado por defecto
                    }}
                >
                    Contacto <ArrowDropDownIcon />
                </a>
                <Menu
                    sx={{ top: "195px", left: "190px" }}
                    id="productos-menu"
                    anchorEl={contactosAnchorEl}
                    open={Boolean(contactosAnchorEl)}
                    onClose={handleContactosMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}

                >
                    <MenuItem onClick={handleClose}>
                        <a href={`mailto:${direccionCorreo}`} style={{ textDecoration: 'none', color: '#706F6F' }}>
                            Correo Electr&oacute;nico
                        </a>
                        {/*  </Link> */}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <a style={{ color: '#706F6F', textDecoration: 'none' }} href='https://api.whatsapp.com/send?phone=56957326254' target='_blank'>
                            Whatsapp
                        </a>
                    </MenuItem>
                </Menu>
            </MenuItem>


            <MenuItem>
                <a
                    aria-controls="productos-menu"
                    aria-haspopup="true"
                    onClick={handleProductsMenuOpen}
                    style={{
                        color: '#706F6F',
                        display: 'inline-flex', // Añadir display inline-flex
                        alignItems: 'center',  // Asegurar alineación vertical
                        textDecoration: 'none' // Opcional, para remover el subrayado por defecto
                    }}
                >
                    Productos <ArrowDropDownIcon />
                </a>
                <Menu
                    sx={{ top: "195px", left: "190px" }}
                    id="productos-menu"
                    anchorEl={productsAnchorEl}
                    open={Boolean(productsAnchorEl)}
                    onClose={handleProductsMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}

                >
                    <MenuItem onClick={handleClose}>
                        <Link to="/ilumen" style={{ color: '#706F6F', textDecoration: 'none' }}>
                            iLumen
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Link to="/" style={{ color: '#706F6F', textDecoration: 'none' }} >iTOTEM</Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Link to="/" style={{ color: '#706F6F', textDecoration: 'none' }}>Productos Educativos</Link>
                    </MenuItem>
                </Menu>
            </MenuItem>




            {isAuthenticated !== true && (
                <>
                    <MenuItem onClick={handleMenuClose}>
                        <Link to="/registro" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Button variant="contained">
                                Crear Cuenta
                            </Button>
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Button variant="outlined">
                                Entrar
                            </Button>
                        </Link>
                    </MenuItem>
                </>
            )}
            {isAuthenticated && (
                <>
                    <MenuItem onClick={handleMenuClose}>
                        <Link to="/Dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Button variant="contained">
                                Panel
                            </Button>
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <Button color="error" variant="contained" onClick={handleLogout}>
                            Salir
                        </Button>
                    </MenuItem>

                </>
            )}
        </Menu>
    );

    return (
        <ThemeProvider theme={theme_navbar}>
            <AppBar position="static" >
                <Toolbar >
                    <Grid container justifyContent="space-between" alignItems="center" >
                        {/*  <Grid item xs={6} sm={4} md={4} lg={4} xl={4}> */}
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Link to="/">
                                    <img src={khrono_latam_logo} alt="Logo" style={{ width: '80px', height: '60px', marginRight: '10px' }} />
                                </Link>
                                <Typography variant="h6" component="div" sx={{ color: '#0D0D0D' }}>
                                    {isXsOrSm ? (
                                        <>
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="menu"
                                                onClick={handleMenuClick}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                            <MobileMenu />

                                        </>
                                    ) : (
                                        <>
                                            <Grid container spacing={1} alignItems="center">
                                                {/*  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                    <Button color="inherit" onClick={handleMenuClick} sx={{ color: '#706F6F' }}>
                                                        Blog
                                                    </Button>
                                                </Grid> */}
                                                {/*   <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                    <Button color="inherit" sx={{ color: '#706F6F' }}>
                                                        Contacto
                                                    </Button>
                                                </Grid> */}
                                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} sx={{ marginRight: '10px' }}>
                                                    <Button
                                                        color="inherit"
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={handleClickContacto}
                                                        style={{
                                                            color: '#706F6F',
                                                            display: 'inline-flex', // Añadir display inline-flex
                                                            alignItems: 'center',  // Asegurar alineación vertical
                                                            textDecoration: 'none' // Opcional, para remover el subrayado por defecto
                                                        }}
                                                    >
                                                        Contacto  <ArrowDropDownIcon />
                                                    </Button>
                                                </Grid>

                                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                    <Button
                                                        color="inherit"
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={handleClick}
                                                        style={{
                                                            color: '#706F6F',
                                                            display: 'inline-flex', // Añadir display inline-flex
                                                            alignItems: 'center',  // Asegurar alineación vertical
                                                            textDecoration: 'none' // Opcional, para remover el subrayado por defecto
                                                        }}
                                                    >
                                                        Productos  <ArrowDropDownIcon />
                                                    </Button>
                                                </Grid>

                                            </Grid>

                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleClose}>
                                                    <Link to="/ilumen" style={{ color: '#706F6F', textDecoration: 'none' }}>
                                                        iLumen
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem onClick={handleClose}>
                                                    <Link to="/" style={{ color: '#706F6F', textDecoration: 'none' }}>iTOTEM</Link>
                                                </MenuItem>
                                                <MenuItem onClick={handleClose}>
                                                    <Link to="/" style={{ color: '#706F6F', textDecoration: 'none' }}>Productos Educativos</Link>
                                                </MenuItem>

                                            </Menu>

                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorElContacto}
                                                keepMounted
                                                open={Boolean(anchorElContacto)}
                                                onClose={handleCloseContacto}
                                            >
                                                <MenuItem onClick={handleCloseContacto}>
                                                    <a href={`mailto:${direccionCorreo}`} style={{ textDecoration: 'none', color: '#706F6F' }}>
                                                        Correo Electr&oacute;nico
                                                    </a>
                                                </MenuItem>
                                                <MenuItem onClick={handleCloseContacto}>
                                                    <a style={{ color: '#706F6F', textDecoration: 'none' }} href='https://api.whatsapp.com/send?phone=56957326254' target='_blank'>
                                                        Whatsapp
                                                    </a>
                                                </MenuItem>

                                            </Menu>
                                        </>
                                    )}
                                </Typography>
                            </div>
                        </Grid>

                        {/*  <Grid item xs={6} sm={8} md={6} lg={6} xl={6} container justifyContent="flex-end" sx={{ gap: "10px" }}> */}
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} container justifyContent="flex-end" sx={{ gap: "10px" }}>
                            <Hidden smDown>
                                {isAuthenticated !== true && (
                                    <>
                                        <Link to="/registro">
                                            <Button variant="contained">
                                                Crear Cuenta
                                            </Button>
                                        </Link>

                                        <Link to="/login">
                                            <Button variant="outlined">
                                                Entrar
                                            </Button>
                                        </Link>
                                    </>
                                )}

                                {isAuthenticated && (
                                    <>
                                        <Link to="/Dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <Button variant="contained">
                                                Panel
                                            </Button>
                                        </Link>
                                        <Button color="error" variant="contained" onClick={handleLogout}>
                                            Salir
                                        </Button>
                                    </>
                                )}
                            </Hidden>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}
