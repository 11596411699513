import React from 'react';
import { Container } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function VolverInicio() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Container  maxWidth={false}>
            <div
                style={{
                    position: 'fixed',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    backgroundColor: '#404000',
                    color: 'white',
                    padding: '10px',
                    zIndex: '1000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '0px', // No agregar borde redondeado
                }}
                onClick={scrollToTop}
            >
                <ArrowUpwardIcon />
                <span style={{ marginLeft: '8px', fontSize: '16px' }}>Volver a Khronolatam</span>
            </div>
        </Container>
    );
}
