import { createTheme } from '@mui/material/styles';

const fontSize_P = {
    xs: '18px',
    sm: '15px',
    md: '20px',
    lg: '20px',
    xl: '20px',
};


export const theme_formulario_ilumen = createTheme({
    palette: {
        primary: {
            main: '#ff5b00',
        },
        secondary: {
            main: "#ff5b00",
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                },
                body2: {
                    color: '#706F6F'
                },
                h6: {
                    color: 'black',
                    fontSize: '15px',
                    textAlign: 'center'
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    marginBottom: '20px',
                    marginTop: '20px'
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: 'none'
                },
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'collapse'
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
            }
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    marginBottom:'4px'
                },
            }
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding:'0'
                },
            }
        },


    }
});
