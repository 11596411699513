import React from 'react';
import MainBanner_Ilumen from './MainBanner_Ilumen/MainBanner_Ilumen';
import Caracteristicas_Ilumen from './Caracteristicas_Ilumen/Caracteristicas_Ilumen';
import Slider_ComunidadEducativa from '../SliderComunidadEducativa/Slider_ComunidadEducativa';
import { Container } from '@mui/material';



export default function IlumenPage() {

    return (
        <>
                <MainBanner_Ilumen />
                <Caracteristicas_Ilumen />
                <div style={{marginBottom: '14px'}}>
                <Slider_ComunidadEducativa  />
                </div>
        </>
    )
}



