import { createTheme } from '@mui/material/styles';


export const theme_codigo_restablecer_password = createTheme({
    palette: {
        primary: {
            main: '#ff5b00',
        },
        secondary: {
            main: "#ff5b00",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
                body1: {
                    margin: 5,
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textAlign: 'justify'
                },
                body2:{
                    textAlign: 'justify', 
                    color: '#706F6F'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: "#fafafa" 
                }
            }
        },


    },

});

