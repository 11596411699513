import { createTheme } from '@mui/material/styles';


export const theme_navbar = createTheme({
    palette: {
        primary: {
            main: '#ff5b00',
        },
        secondary: {
            main: "#ff5b00",
        },
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: '0',
                    background: '#fff', paddingTop: '15px', paddingBottom: '15px', color: '#333', borderRadius: '0'/* , minHeight: '0' */
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform:'none'
                }
            }
        },
    },
});
