import { createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_EDUTECH_PROTO = process.env.REACT_APP_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = process.env.REACT_APP_API_EDUTECH_HOST;
const API_EDUTECH_PORT = process.env.REACT_APP_API_EDUTECH_PORT;

export const DatosInstitucionContext = createContext();
export const ComunicacionInternaContext = createContext();
export const DatosSigeContext = createContext();



export const ComunicacionInternaProvider = ({ children }) => {

    const [DatosSige, setDatosSige] = useState({
        nombre_datos_sige: '',
        password_datos_sige: '',
    });


    const [nombre_datos_sige, setNombreDatosSige] = useState('');
    const [password_datos_sige, setPasswordDatosSige] = useState('');

    const [DatosInstitucion, setDatosInstitucion] = useState({
        nombre_institucion: '',
        anio_institucion: '',
        rbd_institucion: '',
        rbdvd_institucion: '',
        direccion_institucion: '',
        telefono_institucion: '',
        email_institucion: '',
        logo_institucion: null,
    });


    const [id_institucion, setIdInstitucion] = useState('');
    const [nombre_institucion, setNombreInstitucion] = useState('');
    const [anio_institucion, setAnioInstitucion] = useState('');
    const [rbd_institucion, setRbdInstitucion] = useState('');
    const [rbdvd_institucion, setRbdvdInstitucion] = useState('');
    const [direccion_institucion, setDireccionInstitucion] = useState('');
    const [telefono_institucion, setTelefonoInstitucion] = useState('');
    const [email_institucion, setEmailInstitucion] = useState('');
    const [logo_institucion, setLogoInstitucion] = useState('');

    const [ComunicacionInterna, setComunicacionInterna] = useState({
        nombre_directivo: '',
        telefono_directivo: '',
        correo_directivo: '',
        nombre_profesor: '',
        telefono_profesor: '',
        correo_profesor: '',
    });


    const [nombre_directivo, setNombreDirectivo] = useState('');
    const [telefono_directivo, setTelefonoDirectivo] = useState('');
    const [correo_directivo, setCorreoDirectivo] = useState('');
    const [nombre_profesor, setNombreProfesor] = useState('');
    const [telefono_profesor, setTelefonoProfesor] = useState('');
    const [correo_profesor, setCorreoProfesor] = useState('');
    const [listTipoEnsenanza, setListTipoEnsenanza] = useState([]);
    const [listColegios, setListColegios] = useState([]);
    const [listDirectivos, setListDirectivos] = useState([]);
    const [listProfeJefe, setListProfeJefe] = useState([]);
    const [listSIGE, setListSIGE] = useState([]);
    const [infoUsuario, setInfoUsuario] = useState([]);


    const guardarIntitucion = async (nombre_colegio, anio, rbd, rbddv, direccion, comuna, telefono, correo, logo) => {
        try {

            const form = { nombre_colegio, anio, rbd, rbddv, direccion, comuna, telefono, correo, logo };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/guardar_institucion`, form, {
                withCredentials: true,
            });

            if (response.data.success) {

                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
                await getListarColegios();

            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const getTipoEnsenanza = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/get_tipo_ensenanza`, {
                withCredentials: true,
            });

            if (response.data.success) {
                setListTipoEnsenanza(response.data.tipo_ensenanza);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const getListarColegios = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/get_listar_colegios`, {
                withCredentials: true,
            });

            if (response.data.success) {
                setListColegios(response.data.instituciones);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const modificarInstitucion = async (id_colegio, nombre_colegio, rbd, rbddv, anio, direccion, comuna, telefono, correo, logo) => {
        try {
            const form = { id_colegio, nombre_colegio, rbd, rbddv, anio, direccion, comuna, telefono, correo, logo };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/modificar_institucion`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });

                await getListarColegios();
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const eliminarInstitucion = async (id_colegio) => {
        try {
            const form = { id_colegio };
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/eliminar_institucion`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
                await getListarColegios();
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };



    const guardarDirectivo = async (nombre_directivo, telefono, correo, cargo_directivo, id_colegio) => {
        try {

            const form = { nombre_directivo, telefono, correo, cargo_directivo, id_colegio };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/guardar_directivo`, form, {
                withCredentials: true,
            });

            if (response.data.success) {

                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const modificarDirectivo = async (id_directivo, nombre_directivo, telefono, correo, cargo_directivo,id_colegio) => {
        try {
            const form = { id_directivo, nombre_directivo, telefono, correo, cargo_directivo ,id_colegio};
            //el objeto form es un formulario que tiene los datos que se enviaran al servidor para modificar la institución
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/modificar_directivo`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });

                await getListarDirectivos();
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getListarDirectivos = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/get_listar_directivos`, {
                withCredentials: true,
            });

            if (response.data.success) {
                setListDirectivos(response.data.directivos);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const eliminarDirectivo = async (id_directivo) => {
        try {
            const form = { id_directivo };
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/eliminar_directivo`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
                await getListarDirectivos();
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };



    const guardarProfesorJefe = async (nombre_profesor_jefe, telefono, correo, id_tipo_ensenanza, letra, id_colegio) => {
        try {

            const form = { nombre_profesor_jefe, telefono, correo, id_tipo_ensenanza, letra, id_colegio };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/guardar_profesor_jefe`, form, {
                withCredentials: true,
            });

            if (response.data.success) {

                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const getListarProfeJefe = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/get_listar_profe_jefe`, {
                withCredentials: true,
            });

            if (response.data.success) {
                setListProfeJefe(response.data.profesorJefe);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const modificarProfesorJefe = async (id_profesor_jefe, nombre_profesor_jefe, telefono, correo, id_tipo_ensenanza, letra, id_colegio) => {
        try {
            const form = { id_profesor_jefe, nombre_profesor_jefe, telefono, correo, id_tipo_ensenanza, letra, id_colegio };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/modificar_profesor_jefe`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });

                await getListarProfeJefe();
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const eliminarProfesorJefe = async (id_profesor_jefe) => {
        try {
            const form = { id_profesor_jefe };
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/eliminar_profesor_jefe`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
                await getListarProfeJefe();
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const guardarSIGE = async (id_colegio, clave) => {
        try {

            const form = { id_colegio, clave };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/guardar_sige`, form, {
                withCredentials: true,
            });

            if (response.data.success) {

                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const getListarSIGE = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/get_listar_sige`, {
                withCredentials: true,
            });

            if (response.data.success) {
                setListSIGE(response.data.sige);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const eliminarSIGE = async (id_sige) => {
        try {
            const form = { id_sige };
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/eliminar_sige`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
                await getListarSIGE();
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getObtenerUsuario = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/obtener_usuario`, {
                withCredentials: true,
            });

            if (response.data.success) {
                const usuario = response.data.usuario;
                const primeraLetraNombre = usuario.nombre.charAt(0);
                setInfoUsuario(usuario);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const ComunicacionInternaContextValue = {
        ComunicacionInterna,
        setComunicacionInterna,
        nombre_directivo,
        setNombreDirectivo,
        telefono_directivo,
        setTelefonoDirectivo,
        correo_directivo,
        setCorreoDirectivo,
        nombre_profesor,
        setNombreProfesor,
        telefono_profesor,
        setTelefonoProfesor,
        correo_profesor,
        setCorreoProfesor,
        getTipoEnsenanza,
        listTipoEnsenanza,
        guardarIntitucion,
        getListarColegios,
        listColegios,
        modificarInstitucion,
        eliminarInstitucion,
        guardarDirectivo,
        modificarDirectivo,
        eliminarDirectivo,
        listDirectivos,
        getListarDirectivos,
        guardarProfesorJefe,
        getListarProfeJefe,
        listProfeJefe,
        modificarProfesorJefe,
        eliminarProfesorJefe,
        guardarSIGE,
        getListarSIGE,
        listSIGE,
        eliminarSIGE,
        getObtenerUsuario,
        infoUsuario



    };

    return (
        <ComunicacionInternaContext.Provider value={ComunicacionInternaContextValue}>
            {children}
        </ComunicacionInternaContext.Provider>
    );

};




