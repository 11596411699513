import { createTheme } from '@mui/material/styles';

export const theme_caracteristicas_ilumen = createTheme({
    palette: {
        primary: {
            main: '#dd0a0a',
        },
        secondary: {
            main: "#008CFF",
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                },
                h5: {
                    textAlign: 'center',
                    marginTop: '1rem',
                    color: '#dd0a0a'
                },
                body1: {
                    textAlign: 'center',
                    marginTop: '1rem',
                    color: '#706F6F'
                },
                p: {
                    color: '#706F6F',
                    textAlign: 'justify',
                }


            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: '20px',
                    textAlign: 'center'
                },
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent'
                },
            }
        },

    }
});
