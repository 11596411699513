import React from 'react';
import img_docentes from '../../assets/img/docentes_editada.png';
import img_estudiantes from '../../assets/img/estudiantes_editada.png';
import img_personal_aseo from '../../assets/img/personal_aseo_editada.png'
import img_directivos from '../../assets/img/directivos_editada.png'
import img_apoderados from '../../assets/img/apoderados_editada.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const images = [
    img_directivos,
    img_docentes,
    img_estudiantes,
    img_apoderados,
    img_personal_aseo
];

const settings = {
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    arrows: false,
};

export default function Slider_ComunidadEducativa() {
    const sliderRef = React.useRef(null);

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    return (
        <Slider ref={sliderRef} {...settings}>
            {images.map((image, index) => (
                <div key={index}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <img
                            src={image}
                            alt={`Photo ${index + 1}`}
                            style={{
                                width: '100%',
                                maxHeight: '70vh',
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                </div>
            ))}
        </Slider>
    );
}
