import { createTheme } from '@mui/material/styles';

export const theme_mainbanner = createTheme({
    palette: {
        primary: {
            main: '#dd0a0a',
        },
        secondary: {
            main: "#008CFF",
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                },
                h1: {
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                    fontSize: '60px',
                    textAlign: 'left',
                    color: '#2B2C5D'
                },
                h4: {
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                    color: '#dd0a0a',
                    textAlign: 'left'
                },
                body1: {
                    marginBottom: '1rem',
                    color: '#706F6F',
                    textAlign: 'left'
                }
            }
        },
    }
});
