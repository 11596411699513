import React, { useState, useContext, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme_colegios_ingresados } from './theme_colegios_ingresados';
import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ComunicacionInternaContext } from "../../../context/FormsContext";

import { DataGrid } from '@mui/x-data-grid';
import { capitalize } from "../../Utils/Utils";

const columns = [
    {
        field: 'nombre',
        headerName: <span style={{ fontWeight: 'bold' }}>Nombre Instituci&oacute;n</span>,
        width: 250,
        editable: false,
    },
    {
        field: 'rbd',
        headerName: <span style={{ fontWeight: 'bold' }}>RBD</span>,
        width: 100,
        editable: false,
    },
    {
        field: 'direccion',
        headerName: <span style={{ fontWeight: 'bold' }}>Direcci&oacute;n</span>,
        width: 250,
        editable: false,
    },
    {
        field: 'comuna',
        headerName: <span style={{ fontWeight: 'bold' }}>Comuna</span>,
        sortable: false,
        width: 160,
    },
    {
        field: 'telefono',
        headerName: <span style={{ fontWeight: 'bold' }}>Tel&eacute;fono</span>,
        sortable: false,
        width: 160,
    },
    {
        field: 'logo',
        headerName: <span style={{ fontWeight: 'bold' }}>Logo</span>,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        width: 160,
        renderCell: (params) => (
            <img src={params.value} alt="Logo" style={{ maxWidth: '90%', maxHeight: '90%' }} />
        ),
    },
];


export default function ColegiosIngresados() {

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const { listColegios, getListarColegios } = useContext(ComunicacionInternaContext);

    const [mostrarTabla, setMostrarTabla] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {

                await getListarColegios();

                console.log('Ambas funciones se han ejecutado con éxito.');
                setMostrarTabla(true);
            } catch (error) {
                console.error('Ocurrió un error al ejecutar las funciones:', error);
            }
        };

        fetchData();
    }, []);

    console.log(listColegios)

    var rows = []
    if (listColegios) {
        listColegios.map((institucion, index) => {
            rows.push({ id: institucion.id_colegio, nombre: capitalize(institucion.nombre), rbd: institucion.rbd + "-" + institucion.rbddv, direccion: capitalize(institucion.direccion), comuna: capitalize(institucion.comuna), telefono: institucion.telefono, logo: institucion.logo })
        })
    }


    return (
        <ThemeProvider theme={theme_colegios_ingresados}>
            <Container maxWidth="lg">
                <div role="presentation" onClick={handleClick}>

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard"
                            style={{ color: '#706F6F', textDecoration: 'none' ,fontWeight:'normal'}}

                        >
                            Formulario
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="/colegios_ingresados"
                            aria-current="page"
                            style={{ color: '#706F6F', textDecoration: 'none' ,fontWeight:'normal'}}
                        >
                            Instituciones Ingresadas
                        </Link>
                    </Breadcrumbs>
                </div>

                <Box sx={{ padding: 2, border: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'white' }} borderRadius={2}>
                    <Box sx={{ height: 'auto', width: '100%' }}>
                        <Typography variant="h5" >
                            Instituciones
                        </Typography>

                        <Typography variant="body1" component="p">
                            A continuación, se presenta una tabla que detalla las instituciones asociadas al usuario.
                        </Typography>

                        <DataGrid
                            autoHeight
                            autoWidth
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            rowHeight={30}
                            columnHeaderHeight={40}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </Box>
            </Container >
        </ThemeProvider >
    )
}
