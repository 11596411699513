import { createTheme } from '@mui/material/styles';

export const theme_mainbanner = createTheme({
    palette: {
        primary: {
            main: '#088C42',
        },
        secondary: {
            main: "#008CFF",
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                },
                h1: {
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                    fontSize: '60px',
                    textAlign: 'justify',
                    color: '#2B2C5D'
                },
                h4: {
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                    color: '#008CFF',
                    textAlign: 'left'
                },
                body1: {
                    marginBottom: '1rem',
                    color: '#706F6F',
                    textAlign: 'left'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'inherit',
                    backgroundColor: '#ff5b00!important',
                    borderColor: '#ff5b00 !important'
                }
            }
        }
    }
});
