import { Grid, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import img_view from '../../assets/images/view_web.png';
import { theme_vista_web } from '../VistaWeb/theme_vista_web';
import { ThemeProvider } from "@mui/material/styles";
import vista_web from '../../assets/img/vista_web_ilumen.png'

export default function ViewWeb() {
    return (
        <div>
            <ThemeProvider theme={theme_vista_web}>
                <Container maxWidth={false}>
                    <Grid container spacing={2} justifyContent="center">
                        {/* Grid izquierdo */}
                        <Grid item xs={0} sm={1} style={{ maxWidth: '8.333333%' }} />

                        {/* Grid del medio con imagen */}
                        <Grid item xs={12} sm={10} style={{ maxWidth: '83.333333%' }}>
                            <img
                                src={vista_web}
                                alt="Imagen del medio"
                                style={{ width: '100%', display: 'block' }}
                            />
                        </Grid>

                        {/* Grid derecho */}
                        <Grid item xs={0} sm={1} style={{ maxWidth: '8.333333%' }} />
                    </Grid>
                </Container>
            </ThemeProvider>
        </div>
    )
}
