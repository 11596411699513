import { createTheme } from '@mui/material/styles';

export const theme_soluciones_vinculo_educativo = createTheme({
    palette: {
        primary: {
            main: '#088C42',
        },
        secondary: {
            main: "#008CFF",
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                },
                h5: {
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                    fontSize: '60px',
                    textAlign: 'left',
                    color: '#2B2C5D'
                },
              

            }
        },
        
     
    }
});
