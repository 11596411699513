import { createTheme } from '@mui/material/styles';


export const theme_app_login = createTheme({
    palette: {
        primary: {
            main: '#ff5b00',
        },
        secondary: {
            main: "#ff5b00",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    '&.MuiContainer-root': {
                        paddingLeft: 0,
                        paddingRight: 0,
             
                      }
                     
                }
                
            }
        }

    },

});

