export const fontSize = {
    xl: "16px",
    lg: "16px",
    md: "16px",
    sm: "15px",
    xs: "15px",
}

export const fontSizeTable = {
    xl: "12px",
    lg: "12px",
    md: "12px",
    sm: "12px",
    xs: "11px",
}

export const fontSizeDialog = {
    xl: "14px",
    lg: "14px",
    md: "14px",
    sm: "14px",
    xs: "14px",
  }

  export const capitalize = (frase) => {
    if (typeof frase !== 'string') {
        // Puedes devolver un valor predeterminado o lanzar un error
        return ''; // Valor predeterminado
        // O lanzar un error:
        // throw new Error('La función capitalize espera una cadena como argumento');
    }
    return frase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
