import React from 'react';
import img2 from '../../../assets/img/ilumen-desktop-mobile-01.png';
import img3 from '../../../assets/img/ilumen-desktop-grafica-colegio.png';
import img4 from '../../../assets/img/ilumen_img4.png';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Avatar, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, Link as MuiLink, Hidden, Paper } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { theme_caracteristicas_ilumen } from './theme_caracteristicas_ilumen';
import vista_movil_web_ilumen from '../../../assets/img/vista_movil_web_ilumen.png';
import captura2_ilumen from '../../../assets/img/captura2_ilumen.png';

const fontSize_P = {
    xs: '18px',
    sm: '15px',
    md: '20px',
    lg: '20px',
    xl: '20px',
};

const fontSize_H5 = {
    xs: '20px',
    sm: '20px',
    md: '25px',
    lg: '30px',
    xl: '30px',
};

const fontSize_Body1 = {
    xs: '15px',
    sm: '15px',
    md: '20px',
    lg: '20px',
    xl: '20px',
};

const fontSize_titulo = {
    xs: '20px',
    sm: '20px',
    md: '25px',
    lg: '28.8px',
    xl: '28.8px',
};

const fontSize_parrafo = {
    xs: '12px',
    sm: '12px',
    md: '18px',
    lg: '18px',
    xl: '18px',
};

export default function Caracteristicas_Ilumen() {
    return (
        <ThemeProvider theme={theme_caracteristicas_ilumen}>
            <Paper elevation={3} >
                <Container maxWidth="xl">
                    <div style={{ backgroundColor: 'white', color: 'black', padding: '32px', display: 'flex', flexDirection: 'column', gap: '60px' }}>
                        <div>
                            <Typography variant="h5" sx={{ fontSize: fontSize_H5 }}>
                                <span style={{ fontWeight: 'bold' }}>Toda la información</span> de manera <span style={{ fontWeight: 'bold' }}>ordenada</span> y de <span style={{ fontWeight: 'bold' }}>fácil acceso</span>
                            </Typography>
                            <Typography variant="body1" sx={{fontSize: fontSize_Body1}}>Facilitamos la gestión de la asistencia escolar de manera eficiente y comunicativa</Typography>
                        </div>

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                                <MuiLink to="/ruta-de-destino" color="primary">Mejora la administración estudiantil</MuiLink>
                                <Typography variant="h1" color="primary" sx={{ fontSize: fontSize_titulo, fontWeight: '700', lineHeight: '1.5' }}>Administra la asistencia escolar y monitorea la deserción con anticipación</Typography>
                                <Typography variant="p" sx={{ fontSize: fontSize_parrafo }}>
                                    Ilumen permite administrar la asistencia escolar de forma ordenada y en tiempo real. Puede acceder a toda la información en una sola aplicación, que avisará con anticipación mediante alertas, sobre los casos que necesitan supervisión.
                                    <br />
                                    Administra la comunicación con los apoderados mediante SMS y/o correo electrónico.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <img alt='' src={vista_movil_web_ilumen} style={{ width: '100%', height: 'auto' }} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>
                            <Hidden mdDown>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <img alt='' src={captura2_ilumen} style={{ width: '100%', height: 'auto' }} />
                                </Grid>
                            </Hidden>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                                <MuiLink to="/ruta-de-destino" color="primary">Atención remota y comunicación eficiente</MuiLink>
                                <Typography variant="h1" color="primary" sx={{ fontSize: fontSize_titulo, fontWeight: '700', lineHeight: '1.5' }}>Conecta la comunidad con la información en tiempo real</Typography>
                                <Typography variant="p" sx={{ fontSize: fontSize_parrafo }}>Ilumen permite la atención remota a través de mensajes SMS, facilitando la comunicación entre los apoderados y los servicios de la institución. Con esta función, los usuarios pueden recibir información en tiempo real sobre la asistencia de los estudiantes y resolver dudas de manera rápida y eficiente.</Typography>
                                <Typography variant="body1" color="primary" sx={{ fontSize: '15px', marginTop: '-10px', fontWeight: 'bold' }}>
                                    Solicita el iTOTEM de Información para el hall del Establecimiento<br />
                                    Facilita la notificación a apoderados
                                </Typography>
                            </Grid>

                            <Hidden mdUp>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <img alt='' src={img3} style={{ width: '100%', height: 'auto' }} />
                                </Grid>
                            </Hidden>
                        </Grid>
                    </div>
                </Container>
            </Paper>
        </ThemeProvider>
    );
}