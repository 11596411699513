import React, { useContext, useState, useRef } from 'react';
import { Container, TextField, MenuItem, Button, Typography, Box, Divider, Link as MuiLink } from '@mui/material';
import { useTheme, ThemeProvider } from '@mui/material';
import { theme_registrar } from '../../components/Registrarse/theme_registrar';
import { Link as RouterLink } from 'react-router-dom';
import Header from '../Navbar/Navbar';
import ReCAPTCHA from "react-google-recaptcha";
import CountryFlag from 'react-country-flag'; // Importa el componente de la bandera
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import logoKhrono from '../../assets/img/LOGO_KHRONO.png';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const validationSchema = Yup.object().shape({
    nombre: Yup.string().matches(/^[A-Za-záéíóúüÁÉÍÓÚÜñÑ\s']+$/, 'Solo se permiten letras y símbolos acentuados en este campo').required('Este campo es obligatorio'),
    run: Yup.string().test('validarRUN', 'RUN inválido', function (value) { return validarRUN(value); }).required('Este campo es obligatorio'),
    email: Yup.string().email().required(),
    remail: Yup.string().oneOf([Yup.ref('email'), null], 'Los emails deben coincidir'),//.required(),
    phone: Yup.string().matches(/^[0-9]{9}$/, 'Número de teléfono inválido').required(),
    rephone: Yup.string().oneOf([Yup.ref('phone'), null], 'Los números de teléfono deben coincidir'),//.required(),
    password: Yup.string().required('Este campo es obligatorio'),
    repassword: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir'),//.required(),
});

function validarRUN(run) {
    // Eliminar puntos y guiones del RUN y convertir a mayúsculas
    run = run.replace(/\./g, '').replace(/-/g, '').toUpperCase();

    // Verificar el formato del RUN
    if (!/^[0-9]+[0-9Kk]$/.test(run)) {
        return false;
    }

    // Separar el cuerpo del RUN del dígito verificador
    var cuerpo = run.slice(0, -1);
    var dv = run.slice(-1);

    // Calcular el dígito verificador esperado
    var suma = 0;
    var multiplo = 2;
    for (var i = cuerpo.length - 1; i >= 0; i--) {
        suma += parseInt(cuerpo.charAt(i)) * multiplo;
        if (multiplo < 7) {
            multiplo += 1;
        } else {
            multiplo = 2;
        }
    }
    var resto = suma % 11;
    var dvEsperado = 11 - resto;
    if (dvEsperado === 10) {
        dvEsperado = 'K';
    } else if (dvEsperado === 11) {
        dvEsperado = 0;
    }

    // Comparar el dígito verificador ingresado con el dígito verificador esperado
    return dv == dvEsperado;
}

const RegisterPage = () => {

    const { registerRequest } = useContext(AuthContext);

    const handleRegister = (response) => {
        if (response.status === 'success') {
            toast.success(response.message, {
                position: "bottom-left",
                autoClose: 1000,
            });
        } else if (response.status === 'error') {
            toast.error(response.message, {
                position: "bottom-left",
                autoClose: 1000,
            });
        } else {
            toast.success(response.message, {
                position: "bottom-left",
                autoClose: 1000,
            });
        }
    };

    /** PÁISES */
    const paises = [
        {
            countryCodeNumber: '56',
            countryCodeText: 'CL'
        },
        {
            countryCodeNumber: '57',
            countryCodeText: 'CO'
        },
        {
            countryCodeNumber: '54',
            countryCodeText: 'AR'
        },
        {
            countryCodeNumber: '51',
            countryCodeText: 'PE'
        }
    ];

    /** CAPTCHA  */
    const captcha = useRef(null);
    const [captchaIsDone, setCaptchaIsDone] = useState(null);
    const SITE_KEY = REACT_APP_RECAPTCHA_SITE_KEY;
    const onChange = () => {
        if (!captcha.current.getValue()) {
            setCaptchaIsDone(false);
            return
        }
        setCaptchaIsDone(true);
    };

    /** FORMULARIO */
    const [formData, setFormData] = useState({
        nombre: '',
        run: '',
        email: '',
        countryCode: '56',
        phone: '',
        password: '',
        recaptcha: '',
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await validationSchema.validate(formData, { abortEarly: false });
        }
        catch (error) {
            // Hay errores de validación, actualiza el estado de los errores
            const validationErrors = {};
            error.inner.forEach((e) => {
                validationErrors[e.path] = e.errors[0];
            });
            setErrors(validationErrors);
            return
        }

        if (!captchaIsDone) {
            // Si el ReCAPTCHA no se ha completado, muestra un mensaje de error.
            setCaptchaIsDone(false);
            throw new Error(`Captcha no completado`)
            return;
        }

        formData.rephone = formData.phone;
        formData.repassword = formData.password;
        formData.remail = formData.email;
        formData.tokenReCaptcha = captcha.current.getValue();

        const response = await registerRequest(formData);

        // Si la solicitud es exitosa, puedes manejar la respuesta aquí
        handleRegister(response.data);
        // Restablece el formulario después de enviar los datos
        setFormData({
            nombre: '',
            run: '',
            email: '',
            countryCode: '+56',
            phone: '',
            password: '',
            recaptcha: '',
        });
        captcha.current.reset();
    };

    return (
        <ThemeProvider theme={theme_registrar}>
            <Box component='div' sx={{ height: '100%' }}>
                <Container maxWidth="xs" sx={{ display: 'flex', padding: '10px' }}>
                    <Container maxWidth="xs" style={{ padding: '1.5rem' }}
                        sx={{
                            display: 'flex',
                            width: '470px',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                            padding: '2rem',
                        }}>
                        <Box component='img' src={logoKhrono} width='80%' height='auto' margin='auto' />
                        <Typography variant="body1" align="center" style={{ margin: 5, fontSize: '26px' }}>
                            Registro
                        </Typography>
                        <form onSubmit={handleSubmit}>

                            <TextField
                                size='small'
                                label="Nombre"
                                variant="outlined"
                                margin="normal"
                                name="nombre"
                                type='text'
                                value={formData.nombre}
                                onChange={handleInputChange}
                                helperText={errors.nombre}  // Muestra el mensaje de error debajo del campo si existe un error
                                error={!!errors.nombre}    // Muestra el estilo de error si hay un error
                                required
                                fullWidth
                                sx={{ background: "#fafafa" }}
                            />
                            <TextField
                                size='small'
                                label="RUN"
                                variant="outlined"
                                type='text'
                                margin="normal"
                                name="run"
                                value={formData.run}
                                onChange={handleInputChange}
                                helperText={errors.run}  // Muestra el mensaje de error debajo del campo si existe un error
                                error={!!errors.run}    // Muestra el estilo de error si hay un error
                                required
                                fullWidth
                                sx={{ background: "#fafafa" }}
                            />
                            <TextField
                                size='small'
                                label="Correo Electrónico"
                                variant="outlined"
                                margin="normal"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                helperText={errors.email}  // Muestra el mensaje de error debajo del campo si existe un error
                                error={!!errors.email}    // Muestra el estilo de error si hay un error
                                required
                                fullWidth
                                sx={{ background: "#fafafa" }}
                            />
                            <Box display='flex' alignItems='start' mb={2}>
                                <TextField
                                    sx={{ background: "#fafafa" }}
                                    size='small'
                                    label="Código de País"
                                    variant="outlined"
                                    margin="normal"
                                    name="countryCode"
                                    value={formData.countryCode}
                                    onChange={handleInputChange}
                                    style={{ width: 100 }}
                                    select
                                >
                                    {
                                        paises.map((pais, index) => (
                                            <MenuItem key={index} value={pais.countryCodeNumber}>
                                                <CountryFlag countryCode={pais.countryCodeText} style={{ marginRight: 2 }} svg /> +{pais.countryCodeNumber}
                                            </MenuItem>
                                        )
                                        )

                                    }

                                </TextField>
                                <TextField
                                    sx={{ background: "#fafafa" }}
                                    size='small'
                                    label="Número de Teléfono"
                                    variant="outlined"
                                    margin="normal"
                                    type='text'
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    style={{ flex: 1 }}
                                    required
                                    error={Boolean(errors.phone)}
                                    helperText={errors.phone}
                                />
                            </Box>

                            <TextField
                                sx={{ background: "#fafafa" }}
                                size='small'
                                label="Contraseña"
                                variant="outlined"
                                margin="normal"
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                helperText={errors.password}  // Muestra el mensaje de error debajo del campo si existe un error
                                error={!!errors.password}    // Muestra el estilo de error si hay un error
                                required
                                fullWidth
                            />
                            <Box style={{ display: 'flex', marginBottom: "20px", marginTop: "20px", width: '100%' }}>
                                <ReCAPTCHA
                                    ref={captcha}
                                    sitekey={SITE_KEY}
                                    onChange={onChange}
                                    value={formData.recaptcha}

                                    style={{ margin: 'auto' }}
                                />
                            </Box>
                            <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                disabled={!captchaIsDone}>
                                Registrarse
                            </Button>

                            <Divider sx={{ marginY: '20px' }} flexItem>
                                o
                            </Divider>
                        </form>
                        {captchaIsDone === false ? <Typography variant="body2" color="initial" sx={{ marginBottom: "10px", color: "red", fontWeight: "bold" }}>Por favor, complete el reCAPTCHA.</Typography> : null}
                        <Typography variant="body2" color="initial">
                            ¿Ya tienes una cuenta?
                            <MuiLink
                                component={RouterLink}
                                to="/login"
                                color="primary"
                                variant="body2"
                                marginLeft='5px'
                            >Inicia sesión aquí
                            </MuiLink>
                            .
                        </Typography>
                    </Container>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default RegisterPage;