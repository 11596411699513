import React, { useState, useContext, useEffect } from 'react';

import { Box, Container, Grid, InputAdornment, Typography, TextField, Button } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import { ThemeProvider } from "@mui/material/styles";
import { theme_cambiar_password } from './theme_cambiar_password';
import Breadcrumbs from '@mui/material/Breadcrumbs';
/* import Link from '@mui/material/Link'; */
import { Link } from 'react-router-dom';
import { DatosMiPerfilContext } from "../../../context/MiPerfilContext";
import { toast } from "react-toastify";

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}



export default function CambiarPassword() {

    const { cambiarPasswordLogeado } = useContext(DatosMiPerfilContext);


    const [claveActual, setClaveActual] = useState('');
    const [nuevaClave, setNuevaClave] = useState('');
    const [confirmarClave, setConfirmarClave] = useState('');


    const handleChangeClaveActual = (e) => {
        setClaveActual(e.target.value);

    };

    const handleChangeNuevaClave = (e) => {
        setNuevaClave(e.target.value);

    };

    const handleChangeConfirmaClave = (e) => {
        setConfirmarClave(e.target.value);

    };

    const validarDatos = (claveActual, nuevaClave, confirmarClave) => {
        if (!claveActual || !nuevaClave || !confirmarClave ||
            claveActual.trim() === '' || nuevaClave.trim() === '' || confirmarClave.trim() === '') {
            return {
                success: false,
                message: "Por favor, ingrese todos los datos"
            };
        }
        return { success: true };
    };


    const handleGuardar = () => {
        const datosValidados = validarDatos(claveActual, nuevaClave, confirmarClave);

        if (!datosValidados.success) {
            toast.error(datosValidados.message, {
                position: "bottom-left",
                autoClose: 1000,
            });
            return;
        }

        if (nuevaClave !== confirmarClave) {
            toast.error("Las contraseñas no coinciden", {
                position: "bottom-left",
                autoClose: 1000,
            });
            return;
        }

        cambiarPasswordLogeado(claveActual, nuevaClave);
        setClaveActual('');
        setNuevaClave('');
        setConfirmarClave('');
    };


    return (
        <ThemeProvider theme={theme_cambiar_password}>
            <Container maxWidth="lg">
                <div role="presentation" onClick={handleClick}>
                    <Breadcrumbs aria-label="breadcrumb">

                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard"
                            style={{ color: '#706F6F', textDecoration: 'none', fontWeight: 'normal' }}
                        >
                            Formulario
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="/cambiar_password"
                            aria-current="page"
                            style={{ color: '#706F6F', textDecoration: 'none', fontWeight: 'normal' }}
                        >
                            Cambiar Contraseña
                        </Link>
                    </Breadcrumbs>
                </div>
                <Box sx={{ padding: 2, border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px', backgroundColor: 'white' }}>
                    <Box>
                        <Typography variant="body1" >
                            Cambiar Contraseña
                        </Typography>
                        <Typography variant="body2">
                            Una contraseña segura es fundamental para prevenir accesos no autorizados a tu cuenta.
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" sx={{ textAlign: 'left' }}>
                                    Contraseña actual:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="password"
                                    value={claveActual}
                                    onChange={handleChangeClaveActual}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" sx={{ textAlign: 'left' }}>
                                    Nueva contraseña:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="password"
                                    value={nuevaClave}
                                    onChange={handleChangeNuevaClave}
                                    helperText="8 caracteres como mínimo, distingue mayúsculas de minúsculas"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" sx={{ textAlign: 'left' }}>
                                    Vuelve a escribir la contraseña
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="password"
                                    value={confirmarClave}
                                    onChange={handleChangeConfirmaClave}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Button variant="contained" color="success" fullWidth onClick={handleGuardar}>
                                    Guardar
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Link style={{ textDecoration: 'none', color: 'white' }} to='/dashboard'>
                                    <Button variant="contained" color="error" fullWidth>
                                        Cancelar
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}
