import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, Typography, Grid } from '@mui/material';
import canete from '../../assets/images/canete.png';
import curacautin from '../../assets/images/curacautin.png';
import curanilahue from '../../assets/images/curanilahue.png';
import huechuraba from '../../assets/images/huechuraba.png';
import lacisterna from '../../assets/images/lacisterna.png';
import mariapinto from '../../assets/images/mariapinto.png';
import pichidegua from '../../assets/images/pichidegua.png';
import porvenir from '../../assets/images/porvenir.png';
import rengo from '../../assets/images/rengo.png';
import talca from '../../assets/images/talca.png';
import { theme_tipos_instituciones_educativas } from './theme_tipos_instituciones_educativas';
import { ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import jardin_infantil from "../../assets/img/jardin_infantil.jpg"
import img_jardin from "../../assets/img/Jardin.png"
import img_sostenedores_particulares from "../../assets/img/sostenedores_particulares.png"
import img_sostenedores_particulares_subvencionados from "../../assets/img/sostenedores_particulares_subvencionados.png"
import img_sleep from "../../assets/img/slep.png"
import img_corporaciones_municipales from "../../assets/img/corporaciones_municipales.png"
import img_escuelas_de_lenguaje from "../../assets/img/escuelas_de_lenguaje.png"
import img_daem from "../../assets/img/DAEM.png"

const images = [
  img_jardin,
  img_sostenedores_particulares,
  img_sleep,
  img_sostenedores_particulares_subvencionados,
  img_corporaciones_municipales,
  img_escuelas_de_lenguaje,
  img_daem,

];

export default function TiposInstitucionesEducativas() {

  const isXSmallScreen = useMediaQuery('(max-width:600px)');
  const isSmallScreen = useMediaQuery('(max-width:960px)');
  const isMediumScreen = useMediaQuery('(max-width:1280px)');
  const isLargeScreen = useMediaQuery('(max-width:1920px)');
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    variableWidth: true,
    adaptiveHeight: true,
  };

  const fontSize = {
    xs: '18px',
    sm: '15px',
    md: '20px',
    lg: '20px',
    xl: '20px',
  };

  //tamaños
  let imageSize = '250px'; 
  if (isXSmallScreen) {
    imageSize = '150px';
  } else if (isSmallScreen) {
    imageSize = '200px';
  } else if (isMediumScreen) {
    imageSize = '250px';
  } else if (isLargeScreen) {
    imageSize = '250px';
  }

  return (
    <div>
      <ThemeProvider theme={theme_tipos_instituciones_educativas}>
        <Container maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px',
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontSize: fontSize }}>
            Ofrecemos atención y soluciones para todos los colegios de Chile y América Latina
          </Typography>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} sm={11} md={12} lg={12} xl={12}>
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Imagen ${index + 1}`} style={{ maxWidth: '100%', width: imageSize, height: 'auto' }} />
                  </div>
                ))}
              </Slider>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}
