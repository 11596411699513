import React from 'react';
import { Container, Grid, Paper, Typography, Button } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { theme_seccion5 } from './theme_proposito_edutech';

export default function PropositoEdutech() {

  const fontSize_Body1_Left = {
    xs: '24px',
    sm: '24px',
    md: '36px',
    lg: '36px',
    xl: '36px',
  };


  const fontSize_Body1_Right = {
    xs: '18px',
    sm: '18px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  };

  return (
    <div>
      <ThemeProvider theme={theme_seccion5}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            {/* Grid izquierdo */}
            <Grid item xs={12} sm={6}>
              <Paper elevation={0}>
                <Typography variant="body1" sx={{ fontSize: fontSize_Body1_Left,textAlign:'justify' }}>
                  Creamos <span style={{ fontWeight: 'bold' }}>EduTech</span> que permite <span style={{ fontWeight: 'bold' }}>resolver el análisis de datos </span> 
                  para aumentar los <span style={{ fontWeight: 'bold' }}>recursos por ingresos de subvención </span> 
                  y mejorar la <span style={{ fontWeight: 'bold' }}>cobertura curricular</span> 
                </Typography>
              </Paper>
            </Grid>

            {/* Grid derecho */}
            <Grid item xs={12} sm={6}>
              <Paper elevation={0}>
                <Typography variant="body1" sx={{ fontSize: fontSize_Body1_Right }}>
                Organiza a tu equipo de trabajo para que tenga a disposición la mayor cantidad de datos y estadísticas de la gestión escolar en tu colegio
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  )
}
