
import React, { useState, useContext, useEffect } from 'react';
import Navbar from './components/Navbar/Navbar';
import MainBanner from './components/MainBanner/MainBanner';
import VistaWeb from './components/VistaWeb/VistaWeb';
import TiposInstitucionesEducativas from './components/TiposInstitucionesEducativas/TiposInstitucionesEducativas';
import PropositoEdutech from './components/PropositoEdutech/PropositoEdutech';
import SolucionesVinculoEducativo from './components/SolucionesVinculoEducativo/SolucionesVinculoEducativo';
import TrabajoCoordinado from './components/TrabajoCoordinado/TrabajoCoordinado';
import VolverInicio from './components/VolverInicio/VolverInicio';
import Footer from './components/Footer/Footer';
import LoginPage from './components/Login/LoginPage';
import RegisterPage from './components/Registrarse/RegisterPage';
import IlumenPage from './components/Ilumen/IlumenPage';
import Slider_ComunidadEducativa from './components/SliderComunidadEducativa/Slider_ComunidadEducativa';
import Formulario_Ilumen from './components/Ilumen/Formulario_Ilumen/Formulario_Ilumen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Login } from '@mui/icons-material';
import { DashboardPage } from './components/Dashboard/dashboardPage';
import { AuthContext } from './context/AuthContext';
import axios from 'axios';
import AutenticadoMain from './components/Autenticado/Main/AutenticadoMain';
import CambiarPassword from './components/Ilumen/CambiarPassword/CambiarPassword';
import MiPerfil from './components/Ilumen/MiPerfil/MiPerfil';
import RestablecerPassword from './components/RestablecerPassword/RestablecerPassword';
import DatosIngresados from './components/Ilumen/DatosIngresados/DatosIngresadosColegio';
import { DatosMiPerfilProvider } from "./context/MiPerfilContext";
import CodigoRestablecerPassword from "./components/CodigoRestablecerPassword/CodigoRestablecerPassword"
import { ThemeProvider, Typography } from '@mui/material';
import { theme_app_login } from './theme_app_login';


import { ToastContainer } from "react-toastify";
import { Container } from '@mui/material';

//import './App.css';

const API_EDUTECH_PROTO = process.env.REACT_APP_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = process.env.REACT_APP_API_EDUTECH_HOST;
const API_EDUTECH_PORT = process.env.REACT_APP_API_EDUTECH_PORT;

// URL base de la API de autenticación
const API = `${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}`;



function App() {
  const { isAuthenticated, setIsAuthenticated, setEstadoIlumen } = useContext(AuthContext);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };


  const empezarAhoraBotonRef = React.useRef(null);
  useEffect(() => {
    const checkSession = async () => {
      const formtoken = {};
      try {
        const { data } = await axios.post(
          `${API}/validate`,
          formtoken,
          {
            withCredentials: true,
          }
        );

        setIsAuthenticated(data.status);
        setEstadoIlumen(data.estado_ilumen);
      } catch (error) {
        setIsAuthenticated(false);
        console.log(error);
      }
    };

    checkSession();
  }, [setIsAuthenticated]);

  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/dashboard" element={
                <>
                  <ThemeProvider theme={theme_app_login}>
                    <Container maxWidth={false} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                      backgroundColor: '#efefef'
                    }}>
                      <DatosMiPerfilProvider>
                        <DashboardPage />
                        <AutenticadoMain />
                      </DatosMiPerfilProvider>
                    </Container>
                  </ThemeProvider>

                </>
              } />

              <Route path="/cambiar_password" element={
                <>
                  <ThemeProvider theme={theme_app_login}>
                    <Container maxWidth={false} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                      backgroundColor: '#efefef'
                    }}>
                      <DatosMiPerfilProvider>
                        <DashboardPage />
                        <CambiarPassword />
                      </DatosMiPerfilProvider>
                    </Container>
                  </ThemeProvider>

                </>
              } />

              <Route path="/mi_perfil" element={
                <>
                  <ThemeProvider theme={theme_app_login}>
                    <Container maxWidth={false} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                      backgroundColor: '#efefef'
                    }}>
                      <DatosMiPerfilProvider>
                        <DashboardPage />
                        <MiPerfil />
                      </DatosMiPerfilProvider>
                    </Container>
                  </ThemeProvider>

                </>
              } />

              <Route path="/colegios_ingresados" element={
                <>
                  <ThemeProvider theme={theme_app_login}>
                    <Container maxWidth={false} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                      backgroundColor: '#efefef'
                    }}>
                      <DatosMiPerfilProvider>
                        <DashboardPage />
                        <DatosIngresados />
                      </DatosMiPerfilProvider>
                    </Container>
                  </ThemeProvider>

                </>
              } />
            </>

          ) : (
            <>
              <Route path="/login" element={
                <>
                  <ThemeProvider theme={theme_app_login}>
                    <Container maxWidth={false} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                    }}>
                      <Navbar />
                      <LoginPage />
                      <Footer />
                    </Container>
                  </ThemeProvider>
                </>
              }
              />
              <Route path="/registro" element={
                <>
                  <ThemeProvider theme={theme_app_login}>
                    <Container maxWidth={false} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                    }}>
                      <Navbar />
                      <RegisterPage />
                      <Footer />
                    </Container>
                  </ThemeProvider>

                </>
              }
              />
            </>
          )}

          <Route path="/" element={
            <>

              <Navbar />
              <MainBanner empezarAhoraBotonRef={empezarAhoraBotonRef} scrollToRef={scrollToRef} />
              <Slider_ComunidadEducativa />
              <TiposInstitucionesEducativas />
              <PropositoEdutech />
              <SolucionesVinculoEducativo empezarAhoraBotonRef={empezarAhoraBotonRef} />
              <TrabajoCoordinado />
              <VistaWeb />
              <br></br>
              <VolverInicio />
              <Footer />


            </>
          }
          />
          <Route path="/ilumen" element={
            <>
              <ThemeProvider theme={theme_app_login}>
                <Container maxWidth={false} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                }}>
                  <Navbar />
                  <IlumenPage />
                  <Footer />
                </Container>
              </ThemeProvider>

            </>
          }
          />

          <Route path="/formulario1_ilumen" element={
            <>
              <Navbar />
              <Formulario_Ilumen />
              <Footer />
            </>
          }
          />

          <Route path="/cambiar_password" element={
            <>
              <Navbar />
              <CambiarPassword />
              <Footer />
            </>
          }
          />

          <Route path="/restablecer_password" element={
            <>
              <ThemeProvider theme={theme_app_login}>
                <Container maxWidth={false} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                }}>
                  <Navbar />
                  <DatosMiPerfilProvider>
                    <RestablecerPassword />
                  </DatosMiPerfilProvider>
                  <Footer />
                </Container>
              </ThemeProvider>

            </>
          }
          />

          <Route path="/codigo_restablecer_password" element={
            <>
              <ThemeProvider theme={theme_app_login}>
                <Container maxWidth={false} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                }}>
                  <Navbar />
                  <DatosMiPerfilProvider>
                    <CodigoRestablecerPassword />
                  </DatosMiPerfilProvider>
                  <Footer />
                </Container>
              </ThemeProvider>

            </>
          }
          />


        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div >
  );
}

export default App;


