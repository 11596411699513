import React, { useContext, useRef, useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Divider, Link as MuiLink, Alert } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { theme_codigo_restablecer_password } from './theme_codigo_restablecer_password';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from '../../context/AuthContext';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import icono_llave from '../../assets/img/llave.png';
import icono_not_fount from '../../assets/img/informacion.png';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { DatosMiPerfilContext } from "../../context/MiPerfilContext";


const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const CodigoRestablecerPassword = () => {

    const { resetContrasenaCodigo, validacionToken } = useContext(DatosMiPerfilContext);

    const [validateToken, setValidateToken] = useState(false);


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let initialToken = searchParams.get('token');

    console.log('Token de la URL:', initialToken);

    const [token, setToken] = useState(initialToken);



    useEffect(() => {
        const fetchData = async () => {

            const initialTokenExiste = await validacionToken(initialToken);
            console.log(initialTokenExiste)
            setValidateToken(initialTokenExiste);

        };
        fetchData();
    }, [initialToken, validacionToken]);



    const captcha = useRef(null);
    const [captchaIsDone, setCaptchaIsDone] = useState(true);
    const SITE_KEY = REACT_APP_RECAPTCHA_SITE_KEY;
    const onChange = () => {
        setCaptchaIsDone(true);
    };


    const [codigoSeguridad, SetCodigoSeguridad] = useState('');
    const [claveNueva, setClaveNueva] = useState('');
    const [confirmarClave, setConfirmarClave] = useState('');

    const handleChangeCodigoSeguridad = (e) => {
        SetCodigoSeguridad(e.target.value);

    };

    const handleChangeClaveNueva = (e) => {
        setClaveNueva(e.target.value);

    };


    const handleChangeConfirmarClave = (e) => {
        setConfirmarClave(e.target.value);

    };


    const handleGuardarNuevaClave = async () => {
        if (claveNueva !== confirmarClave) {
            toast.error("Las contraseñas no coinciden", {
                position: "bottom-left",
                autoClose: 1000,
            });
            return;
        }

        try {
            await resetContrasenaCodigo(token, codigoSeguridad, claveNueva);
            setClaveNueva('');
            setConfirmarClave('');
            SetCodigoSeguridad('');
        } catch (error) {
            console.error(error);
            toast.error("Error al restablecer la contraseña. Por favor, inténtalo de nuevo.", {
                position: "bottom-left",
                autoClose: 1000,
            });
        }
    };



    return (
        <ThemeProvider theme={theme_codigo_restablecer_password}>
            <Container maxWidth="xs" sx={{ flex: 1, padding: '10px' }}>
                <Container maxWidth="xs"
                    style={{ padding: '1.5rem' }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                        padding: '2rem',
                    }}>
                    {validateToken && (
                        <>
                            <Box component='img' src={icono_llave} width='30%' height='auto' margin='auto' />
                            <Typography variant="body1" align="justify">
                                Cambiar Contraseña
                            </Typography>
                            <Typography variant="body2">
                                Ingresa tu nueva contraseña y recuerda que debe tener 8 caracteres como mínimo, distingue mayúsculas de minúsculas.
                            </Typography>
                            <form >
                                <TextField
                                    size='small'
                                    label="Nueva Contraseña"
                                    variant="outlined"
                                    margin="normal"
                                    name="password"
                                    type='password'
                                    value={claveNueva}
                                    onChange={handleChangeClaveNueva}
                                    required
                                    fullWidth
                                />

                                <TextField
                                    size='small'
                                    label="Confirmar Contraseña"
                                    variant="outlined"
                                    margin="normal"
                                    name="password"
                                    type='password'
                                    value={confirmarClave}
                                    onChange={handleChangeConfirmarClave}
                                    required
                                    fullWidth
                                />

                                <TextField
                                    size='small'
                                    label="Código de Seguridad"
                                    variant="outlined"
                                    margin="normal"
                                    helperText='Ingresa tu código de 6 dígitos.'
                                    type='text'
                                    value={codigoSeguridad}
                                    onChange={handleChangeCodigoSeguridad}
                                    required
                                    fullWidth
                                />

                                <Box style={{ display: 'flex', marginBottom: "20px", marginTop: "20px", width: '100%' }}>
                                    <ReCAPTCHA
                                        ref={captcha}
                                        sitekey={SITE_KEY}
                                        onChange={onChange}
                                        style={{ margin: 'auto' }}
                                    />
                                </Box>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={!captchaIsDone}
                                    type="button"
                                    onClick={handleGuardarNuevaClave}

                                >
                                    Guardar nueva contraseña
                                </Button>

                                <Divider sx={{ marginY: '20px' }} flexItem>
                                    o
                                </Divider>
                                <RouterLink to="/registro" style={{ textDecoration: 'none', textAlign: 'center', display: 'block', color: '#ff5b00' }} >
                                    Crear nueva cuenta
                                </RouterLink>
                            </form>
                        </>


                    )}

                    {!validateToken && (
                        <>
                            <Box component='img' src={icono_not_fount} width='30%' height='auto' margin='auto' />
                            <Typography variant="body1" align="justify">
                                NOT FOUND
                            </Typography>
                            <Typography variant="body2">
                                Lo siento, la página que estás buscando no se puede encontrar.
                            </Typography>
                        </>
                    )}

                </Container>
            </Container>
        </ThemeProvider>
    );
};

export default CodigoRestablecerPassword;