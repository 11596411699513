import React, { useState, useEffect } from 'react';
import { Paper, Container, Grid, Typography, Button, Box } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { theme_mainbanner } from './theme_mainbanner';
import logo_krono_latam from '../../assets/img/LOGO_KHRONO_3.png';


const ResponsiveImage = ({ src, alt }) => {
    const [width, setWidth] = useState('80%');

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            let newWidth = '100%'; 

            if (screenWidth <= 575) {
                newWidth = '50%';
            } else if (screenWidth <= 767) {
                newWidth = '50%';
            } else if (screenWidth <= 991) {
                newWidth = '50%';
            } else if (screenWidth <= 1199) {
                newWidth = '80%';
            } else {
                newWidth = '100%';
            }

            setWidth(newWidth);
        };

        handleResize(); 

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <img src={src} alt={alt} style={{ width }} />;
};

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}



export default function MainBanner({empezarAhoraBotonRef, scrollToRef}) {
    const fontSize_H1 = {
        xs: '32px',
        sm: '32px',
        md: '50px',
        lg: '55px',
        xl: '60px',
    };

    const fontSize_P = {
        xs: '18px',
        sm: '15px',
        md: '20px',
        lg: '20px',
        xl: '20px',
    };


    return (
        <div>
            <ThemeProvider theme={theme_mainbanner}>
                <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                    <Container maxWidth="xl" >
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: 'center', marginBottom: { xs: '2rem', sm: 0 } }}>
                                <Typography variant="h4" component="h1" sx={{ fontSize: fontSize_H1 }}>
                                    Optimizamos tus tiempos para dedicarnos a mejorar la calidad educativa
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ fontSize: fontSize_P, textAlign:"justify" }}>
                                    Contamos con más de 12 años trabajando en la gestión escolar y el procesamiento de datos en sostenedores de todo el país y America Latina. #sabemosloquehacemos
                                </Typography>
                                <Button variant="contained" color="primary"  onClick={() => scrollToRef(empezarAhoraBotonRef)} >
                                    Empezar Ahora
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ResponsiveImage src={logo_krono_latam} alt="banner-image" />
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </ThemeProvider>
        </div>
    )
}
