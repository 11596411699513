import { createTheme } from '@mui/material/styles';

export const theme_seccion5 = createTheme({
    palette: {
        primary: {
            main: '#088C42',
        },
        secondary: {
            main: "#008CFF",
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                body1: {
                    color: '#706F6F!important',
                    marginTop: '0',
                    marginBottom: '1rem',
                    lineHeight: '1.5',
                    textAlign: 'left',
                    fontWeight: '400'
                },
            },
        },
         MuiButton: {
             styleOverrides: {
                 root: {
                     textTransform: 'none',
                 }
             }
         },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: '20px'
                }
            }
        }
    },
});