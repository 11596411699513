import React, { useContext, useRef, useState } from 'react';
import { Container, TextField, Button, Typography, Box, Divider, Link as MuiLink } from '@mui/material';
import { useTheme, ThemeProvider } from '@mui/material';
import { theme_login } from '../../components/Login/theme_login';
import Header from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { GoogleLogin } from '@react-oauth/google';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from '../../context/AuthContext';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import logoKhrono from '../../assets/img/LOGO_KHRONO.png';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;


const LoginPage = () => {
    /** CAPTCHA */
    const captcha = useRef(null);
    const [captchaIsDone, setCaptchaIsDone] = useState(true);
    const { isAuthenticated, login, loginGoogle } = useContext(AuthContext);
    const SITE_KEY = REACT_APP_RECAPTCHA_SITE_KEY;
    const onChange = () => {
        setCaptchaIsDone(true);
    };


    /** GOOGLE LOGIN */
    const responseMessage = async (response) => {
        const { credential } = response;
        loginGoogle(credential);
    };

    const errorMessage = (error) => {
        console.log(error);
    };

    /** FORMULARIO */
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        email: '',
        password: '',
        tokenReCaptcha: '',
    });
    const { email, password } = inputValues;
    const [errors, setErrors] = useState({});
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            //await validationSchema.validate(inputValues, { abortEarly: false });

            if (!captchaIsDone) {
                return;
            }
            inputValues.tokenReCaptcha = captcha.current.getValue();

            try {
                login(inputValues);
            } catch (error) {
                console.error(error);
            }

            // Restablece el formulario después de enviar los datos

            setInputValues({
                email: '',
                password: '',
            });

            if (isAuthenticated) {
                //navigate("/");
                //console.log("NAVIGATE");
            }
        } catch (error) {
            console.error(error);
            // Hay errores de validación, actualiza el estado de los errores
            const validationErrors = {};
            error.inner.forEach((e) => {
                validationErrors[e.path] = e.errors[0];
            });
            setErrors(validationErrors);
        }
    };

    return (
        <ThemeProvider theme={theme_login}>


                <Container maxWidth="xs" sx={{ flex: 1, padding: '10px'}}>
                    <Container maxWidth="xs"

                        style={{ padding: '1.5rem' }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                            padding: '2rem',
                        }}>
                        <Box component='img' src={logoKhrono} width='80%' height='auto' margin='auto' />
                        <Typography variant="body1" align="center" style={{ margin: 5, fontSize: '26px' }}>
                            Iniciar Sesión
                        </Typography>
                        <form onSubmit={handleSubmit}>

                            <TextField
                                size='small'
                                label="Correo Electrónico"
                                variant="outlined"
                                margin="normal"
                                name="email"
                                type='email'
                                value={inputValues.email}
                                onChange={handleInputChange}
                                helperText={errors.email}  
                                error={!!errors.email}    
                                sx={{ background: "#fafafa" }}
                                required
                                fullWidth
                            />


                            <TextField
                                size='small'
                                label="Contraseña"
                                variant="outlined"
                                margin="normal"
                                type="password"

                                name="password"
                                value={inputValues.password}
                                onChange={handleInputChange}
                                helperText={errors.password}  
                                error={!!errors.password}    
                                sx={{ background: "#fafafa" }}
                                required
                                fullWidth
                            />
                            <Box style={{ display: 'flex', marginBottom: "20px", marginTop: "20px", width: '100%' }}>
                                <ReCAPTCHA
                                    ref={captcha}
                                    sitekey={SITE_KEY}
                                    onChange={onChange}
                                    style={{ margin: 'auto' }}
                                />
                            </Box>
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={!captchaIsDone}
                                type="submit"
                            >
                                Iniciar Sesión
                            </Button>

                            <Divider sx={{ marginY: '20px' }} flexItem>
                                o
                            </Divider>
                        </form>
                        <GoogleLogin
                            onSuccess={responseMessage}
                            onError={errorMessage}
                            type='standard'
                            text='login'
                            useOneTap
                            fullwidth />
                        <Typography variant="body2" color="initial" marginTop='20px'>
                            ¿Olvidaste la contraseña?
                            <MuiLink
                                component={RouterLink}
                                to="/restablecer_password"
                                color="primary"
                                variant="body2"
                                marginLeft='5px'
                            >Restablecerla
                            </MuiLink>
                        </Typography>

                        <Typography variant="body2" color="initial" marginTop='10px'>
                            ¿No tienes una cuenta?
                            <MuiLink
                                component={RouterLink}
                                to="/registro"
                                color="primary"
                                variant="body2"
                                marginLeft='5px'
                            >Regístrate
                            </MuiLink>
                        </Typography>

                    </Container>
                </Container>

            {/* </Box> */}
        </ThemeProvider>
    );
};

export default LoginPage;