import { createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_EDUTECH_PROTO = process.env.REACT_APP_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = process.env.REACT_APP_API_EDUTECH_HOST;
const API_EDUTECH_PORT = process.env.REACT_APP_API_EDUTECH_PORT;

export const DatosMiPerfilContext = createContext();


export const DatosMiPerfilProvider = ({ children }) => {
    const [infoUsuario, setInfoUsuario] = useState([]);
    const [primeraLetraNombre, setPrimeraLetraNombre] = useState([]);
    const [imagenUsuario, setImagenUsuario] = useState('');
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
    const [estadoRefrescoMenuDashboard, setEstadoRefrescoMenuDashboard] = useState(false);

    const getObtenerUsuario = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/obtener_usuario`, {
                withCredentials: true,
            });

            if (response.data.success) {
                const usuario = response.data.usuario;
                const primeraLetraNombre = usuario.nombre.charAt(0);
                setInfoUsuario(usuario);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const modificarNombreUsuario = async (id_usuario, nombre) => {
        try {
            const form = { id_usuario, nombre };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/modificar_nombre_usuario`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });

            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const modificarRunUsuario = async (id_usuario, run) => {
        try {
            const form = { id_usuario, run };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/modificar_run_usuario`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });

            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const modificarTelefonoUsuario = async (id_usuario, phone) => {
        try {
            const form = { id_usuario, phone };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/modificar_telefono_usuario`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });

            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const getObtenerPrimeraLetraNombre = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/obtener_primera_letra_nombre`, {
                withCredentials: true,
            });

            if (response.data.success) {
                setInfoUsuario(response.data.usuario);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const guardarImagenUsuario = async (img_usuario) => {
        try {

            const form = { img_usuario };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/guardar_img_perfil`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                setEstadoRefrescoMenuDashboard(false);

                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });

            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getObtenerImagenUsuario = async () => {
        try {
            const response = await axios.get(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/obtener_img_usuario`, {
                withCredentials: true,
            });

            if (response.data.success) {
                const imagenBase64 = response.data.img_usuario;
                setImagenUsuario(imagenBase64);
                setEstadoRefrescoMenuDashboard(false);
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });

            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
                return null;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    };


    const eliminarImagenUsuario = async (id_usuario) => {
        try {
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/eliminar_img_usuario`, { id_usuario }, {
                withCredentials: true,
            });

            if (response.data.success) {

                setImagenUsuario('');
                setEstadoRefrescoMenuDashboard(false);

                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.error('Error al eliminar la imagen de perfil:', e);
            toast.error("Ocurrió un error al eliminar la imagen de perfil", {
                position: "bottom-left",
                autoClose: 1000,
            });
        }
    };


    const cambiarPasswordLogeado = async (claveActual, nuevaClave) => {
        try {
            const form = { claveActual, nuevaClave };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/cambiar_password_logeado`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const resetContrasena = async (reset_form) => {
        try {

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/cambiar_password_unlogin`, reset_form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const resetContrasenaCodigo = async (token, codigo, nuevaClave) => {
        try {
            const form = { token, codigo, nuevaClave };

            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/codigo_resetar_password`, form, {
                withCredentials: true,
            });

            if (response.data.success) {
                toast.success(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            } else {
                toast.error(response.data.message, {
                    position: "bottom-left",
                    autoClose: 1000,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const validacionToken = async (token) => {
        try {
            const form = { token };
            const response = await axios.post(`${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}/validar_token`, form, {
                withCredentials: true,
            });
            return response.data.success;
        } catch (error) {
            console.error(error);
            return false;
        }
    };



    const DatosMiPerfilContextValue = {
        getObtenerUsuario,
        infoUsuario,
        modificarNombreUsuario,
        modificarRunUsuario,
        modificarTelefonoUsuario,
        getObtenerPrimeraLetraNombre,
        guardarImagenUsuario,
        imagenUsuario,
        setImagenUsuario,
        getObtenerImagenUsuario,
        eliminarImagenUsuario,
        imagenSeleccionada,
        setImagenSeleccionada,
        estadoRefrescoMenuDashboard,
        setEstadoRefrescoMenuDashboard,
        cambiarPasswordLogeado,
        resetContrasena,
        resetContrasenaCodigo,
        validacionToken

    };


    return (
        <DatosMiPerfilContext.Provider value={DatosMiPerfilContextValue}>
            {children}
        </DatosMiPerfilContext.Provider>
    );

};
