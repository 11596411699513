import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_EDUTECH_PROTO = process.env.REACT_APP_API_EDUTECH_PROTO;
const API_EDUTECH_HOST = process.env.REACT_APP_API_EDUTECH_HOST;
const API_EDUTECH_PORT = process.env.REACT_APP_API_EDUTECH_PORT;

const API = `${API_EDUTECH_PROTO}://${API_EDUTECH_HOST}:${API_EDUTECH_PORT}`;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [estadoIlumen, setEstadoIlumen] = useState(null);
  //const [cookie, setCookie] = useState(null);

  const login = async (credentials) => {
    try {
      // Realizamos la petición de autenticación a la API utilizando Axios
      const response = await axios.post(
        `${API}/login`,
        { ...credentials },
        { withCredentials: true }
      );

      if (response.data.success) {
        setIsAuthenticated(response.data.success);
        setEstadoIlumen(response.data.estado_ilumen);
        window.location.href = "/dashboard";
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-left",
        autoClose: 1000,
      });
    }
  };

  const loginGoogle = async (token) => {

    const credencial = {
      token: token
    };

    try {
      // Realizamos la petición de autenticación a la API utilizando Axios
      const response = await axios.post(
        `${API}/signin`,
        { token },
        { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: 1000,
        });
        setIsAuthenticated(response.data.success);
        window.location.href = "/";
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-left",
        autoClose: 1000,
      });
    }
  };

  const logout = async () => {
    try {
      const { response } = await axios.get(`${API}/logout`, {
        withCredentials: true,
      });

      if (response.data.success) {
        Cookies.remove("token");
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-left",
        autoClose: 1000,
      });
    }

    setIsAuthenticated(null);
    Cookies.remove("token");
    window.location.href = "/";
  };

  // Función para realizar una solicitud de registro
  const registerRequest = async (user) => {
    try {
      const response = await axios.post(`${API}/signup`, user);
      return response;
    } catch (error) {
      return handleRequestError(error);
    }
  };


  const checkSession = async () => {
    const formtoken = {};
    try {
      const { data } = await axios.post(`${API}/validate`, formtoken, { withCredentials: true });
      setIsAuthenticated(data.status);
      setEstadoIlumen(data.estado_ilumen);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkSession();

    const sessionTimer = setInterval(() => {
      checkSession();
    }, 60 * 1000);

    return () => {
      clearInterval(sessionTimer);
    };
  }, []);

  const authContextValue = {
    isAuthenticated,
    setIsAuthenticated,
    checkSession,
    login,
    loginGoogle,
    registerRequest,
    logout,
    setEstadoIlumen,
    estadoIlumen
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Función para manejar errores de las solicitudes
const handleRequestError = (error) => {
  if (error.response) {
    // El servidor respondió con un código de estado fuera del rango 2xx
    console.error('Error de solicitud:', error.response.data);
    return error.response;
  } else if (error.request) {
    // La solicitud fue hecha pero no se recibió ninguna respuesta
    console.error('No se recibió respuesta del servidor.');
    return Promise.reject('No se recibió respuesta del servidor.');
  } else {
    // Hubo un error durante la configuración de la solicitud
    console.error('Error al configurar la solicitud:', error.message);
    return Promise.reject('Error al configurar la solicitud.');
  }
};

