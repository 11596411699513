import React, { useContext, useRef, useState } from 'react';
import { Container, TextField, Button, Typography, Box, Divider, Link as MuiLink } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { theme_restablecer_password } from './theme_restablecer_password';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from '../../context/AuthContext';
import { Link as RouterLink } from 'react-router-dom';
import icono_candado from '../../assets/img/candado (1).png';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { DatosMiPerfilContext } from "../../context/MiPerfilContext";


const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;


const RestablecerPassword = () => {
    const captcha = useRef(null);
    const [captchaIsDone, setCaptchaIsDone] = useState(true);
    const SITE_KEY = REACT_APP_RECAPTCHA_SITE_KEY;
    const onChange = () => {
        setCaptchaIsDone(true);
    };

    const {resetContrasena } = useContext(DatosMiPerfilContext);

    const [email, setEmail] = useState('');

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);

    };

    const handleCambiarPassword = async () => {
        if (!captchaIsDone) {
            return;
        }

        const reset_form = { tokenReCaptcha: captcha.current.getValue(), email: email };

        await resetContrasena(reset_form);
        setEmail('');       
    };


    return (
        <ThemeProvider theme={theme_restablecer_password}>
            <Container maxWidth="xs" sx={{ flex: 1, padding: '10px' }}>
                <Container maxWidth="xs"
                    style={{ padding: '1.5rem' }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                        padding: '2rem',
                    }}>
                    <Box component='img' src={icono_candado} width='30%' height='auto' margin='auto' />
                    <Typography variant="body1" align="justify">
                        ¿Tienes problemas para entrar?
                    </Typography>
                    <Typography variant="body2">
                        Introduce tu correo electr&oacute;nico  y te enviaremos un enlace para que vuelvas a entrar en tu cuenta.
                    </Typography>
                
                        <TextField
                            size='small'
                            label="Correo Electrónico"
                            variant="outlined"
                            margin="normal"
                            name="email"
                            value={email}
                            onChange={handleChangeEmail}
                            type='email'
                            required
                            fullWidth
                        />

                        <Box style={{ display: 'flex', marginBottom: "20px", marginTop: "20px", width: '100%' }}>
                            <ReCAPTCHA
                                ref={captcha}
                                sitekey={SITE_KEY}
                                onChange={onChange}
                                style={{ margin: 'auto' }}
                            />
                        </Box>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={!captchaIsDone}
                            type="button"
                            onClick={handleCambiarPassword}
                        >
                            Enviar enlace de acceso
                        </Button>

                        <Divider sx={{ marginY: '20px' }} flexItem>
                            o
                        </Divider>
                        <RouterLink to="/registro" style={{ textDecoration: 'none', textAlign: 'center', display: 'block', color: '#ff5b00' }} >
                            Crear nueva cuenta
                        </RouterLink>
                 
                </Container>
            </Container>
        </ThemeProvider>
    );
};

export default RestablecerPassword;