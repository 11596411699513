import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { deepOrange, deepPurple } from '@mui/material/colors';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Grid from '@mui/material/Grid';
import logo_cerrarsesion from '../../assets/img/LOGO_KHRONO.png';
import {
  styled,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";

import { Link, Outlet } from "react-router-dom";

/* import logo from "../../assets/img/LOGO_KHRONO_BN.png"; */
import logo from "../../assets/img/LOGO_KHRONO.png";
import { Tooltip, Typography, Zoom } from "@mui/material";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import HomeIcon from "@mui/icons-material/Home";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import SchoolIcon from '@mui/icons-material/School';

import { DatosMiPerfilContext } from "../../context/MiPerfilContext";

import { AuthContext } from "../../context/AuthContext";

const themeMenu = createTheme({
  palette: {
    mode: "dark", // Modo oscuro
    background: {
      default: "#ffffff", // Color de fondo oscuro
    },
    text: {
      primary: "#374151", // Color de texto claro
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif", // Fuente clara
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          margin: 0;
          padding: 0;
          font-family: 'Roboto, sans-serif';
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      `,
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#fff", // Iconos claros
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#fff", // Enlaces claros
          textDecoration: "none", // Sin decoración
          fontSize: "14px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          width: "300px",
        },
      },
    },
  },
});

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MenuDashboardSec() {

  const { estadoRefrescoMenuDashboard, setEstadoRefrescoMenuDashboard, getObtenerUsuario, infoUsuario, getObtenerPrimeraLetraNombre, imagenUsuario, getObtenerImagenUsuario, guardarImagenUsuario, imagenSeleccionada, setImagenSeleccionada } = useContext(DatosMiPerfilContext);

  const [primeraLetraNombre, setPrimeraLetraNombre] = useState('');


  
  useEffect(() => {
    const fetchData = async () => {
      if (!estadoRefrescoMenuDashboard) {
        try {
          await getObtenerUsuario();
          await getObtenerImagenUsuario();
        
          setEstadoRefrescoMenuDashboard(true); // Marcar que fetchData ya se ejecutó
        } catch (error) {
          console.error('Ocurrió un error al ejecutar las funciones:', error);
        }
      }
    };
  
    fetchData();
  
    if (infoUsuario && infoUsuario.nombre) {
      const primeraLetra = infoUsuario.nombre.charAt(0);
      setPrimeraLetraNombre(primeraLetra);
    }
  }, [imagenUsuario, infoUsuario]);

 

  /* 
    useEffect(() => {
      if (infoUsuario && infoUsuario.nombre) {
        const primeraLetra = infoUsuario.nombre.charAt(0);
        setPrimeraLetraNombre(primeraLetra);
  
      }
  
      
    }, [infoUsuario, imagenUsuario]); */

  
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    // Eliminar la cookie de token
    //removeCookie('token');
    logout();

    // Otras acciones de logout, como redireccionar a la página de inicio de sesión
    // o limpiar el estado de tu aplicación
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showCameraIcon, setShowCameraIcon] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);

  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);

  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedImage(reader.result);

    };
  };


  /* Funciones para agregar imagen de perfil */


  const handleImagenSeleccionada = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64Image = reader.result;
          //setImagenSeleccionada(base64Image);
          await guardarImagenUsuario(base64Image);
          await getObtenerImagenUsuario();

          
        };
        reader.onerror = (error) => {
          console.error('Error al leer la imagen:', error);
        };
      } catch (error) {
        console.error('Error al convertir la imagen a base64:', error);
      }
    }

    //setEstadoRefrescoMenuDashboard(false);
  };

  

  return (
    <ThemeProvider theme={themeMenu}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box component="img" sx={{ height: 54 }} alt="Logo" src={logo} />

            <div style={{ marginLeft: 'auto' }}>
              <Avatar
                sx={{ cursor: 'pointer' }}
                onClick={handleClick}>
                {imagenUsuario !== null && imagenUsuario !== "null" && imagenUsuario !== "" ? (
                  <img src={imagenUsuario} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                ) : (
                  <Typography variant="body1" sx={{ fontSize: '16px', lineHeight: '30px', color: '#fff' }}>
                    {primeraLetraNombre}
                  </Typography>
                )}

              </Avatar>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
              >
                <MenuItem sx={{ color: 'white', justifyContent: 'flex-start', fontSize: '13px' }}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <img src={logo_cerrarsesion} alt="imagen" style={{ width: '100px', height: '30px', marginRight: '10px' }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} onClick={handleLogout} sx={{ textAlign: 'right' }}>
                      Cerrar sesión
                    </Grid>
                  </Grid>
                </MenuItem>

                <MenuItem>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3} lg={3} xl={3}>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={handleImageChange}
                          id="avatar-upload"
                        />
                        <div
                          style={{ display: 'inline-block', position: 'relative' }}
                          onMouseEnter={() => setShowCameraIcon(true)}
                          onMouseLeave={() => setShowCameraIcon(false)}
                        >
                          <Avatar sx={{ bgcolor: deepOrange[500], marginRight: 1 }}>
                            {imagenUsuario !== null && imagenUsuario !== "null" && imagenUsuario !== "" ? (
                              <img src={imagenUsuario} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                            ) : (
                              <Typography variant="body1" sx={{ fontSize: '16px', lineHeight: '30px', color: '#fff' }}>
                                {primeraLetraNombre}
                              </Typography>
                            )}
                            {showCameraIcon && (
                              <label htmlFor="imagenInput">
                                <IconButton component="span" sx={{ position: 'absolute', bottom: 0, right: 0, zIndex: 1 }}>
                                  <PhotoCamera />
                                </IconButton>
                              </label>
                            )}
                            <input
                              type="file"
                              id="imagenInput"
                              accept="image/*"
                              style={{ display: 'none' }}
                              onChange={handleImagenSeleccionada}
                            />
                          </Avatar>


                        </div>
                      </Grid>
                      <Grid item xs={9} sm={9} lg={9} xl={9}>
                        <div>
                          <Typography variant="subtitle1"
                            sx={{
                              marginRight: 1,
                              fontSize: '13px',
                              fontWeight: 'bold',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}>
                            {infoUsuario.nombre}
                          </Typography>
                          <Typography variant="body2"
                            sx={{
                              marginRight: 1,
                              fontSize: '13px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}>
                            {infoUsuario.email}
                          </Typography>
                          <Typography variant="body2" sx={{ marginRight: 1 }}>
                            <Link href="" style={{ fontSize: '13px', textDecoration: 'none', color: 'white' }} >
                              <u style={{ textDecoration: 'none' }}>
                                Suscripciones
                              </u>
                            </Link>
                          </Typography>
                          <Typography variant="body2" sx={{ marginRight: 1 }}>
                            <Link to="/mi_perfil" style={{ fontSize: '13px', textDecoration: 'none', color: 'white' }}>
                              <u style={{ textDecoration: 'none' }}>
                                Mi perfil
                              </u>
                            </Link>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>

        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {/*   <List>
            <Tooltip
              TransitionComponent={Zoom}
              title="Perfil"
              placement="right"
            >
              <Link to="/perfil" onClick={handleDrawerClose}>
                <ListItem key="Perfil" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Perfil" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List> */}
           <List>
            <Tooltip TransitionComponent={Zoom} title="Home" placement="right">
              <Link to="/dashboard" onClick={handleDrawerClose} style={{ textDecoration: 'none' }}>
                <ListItem key="home" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List>


          <List>
            <Tooltip TransitionComponent={Zoom} title="Instituciones" placement="right">
              <Link to="/colegios_ingresados" onClick={handleDrawerClose} style={{ textDecoration: 'none' }}>
                <ListItem key="instituciones_ingresadas" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <SchoolIcon />
                    </ListItemIcon>
                    <ListItemText primary="Instituciones" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List>

          <List>
            <Tooltip
              TransitionComponent={Zoom}
              title="Tu información"
              placement="right"
            >
              <Link to="/mi_perfil" onClick={handleDrawerClose} style={{ textDecoration: 'none' }}>
                <ListItem key="tu_informacion" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tu información"/>
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List>

          {/* <List>
            <Tooltip TransitionComponent={Zoom} title="Home" placement="right">
              <Link to="/" onClick={handleDrawerClose}>
                <ListItem key="Home" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List> */}


          <List onClick={handleLogout}>
            <Tooltip TransitionComponent={Zoom} title="Salir" placement="right" >
              <ListItem key="Salir" disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Salir" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </List>
        </Drawer>
        <Main open={open}>
          <Outlet />
        </Main>
      </Box>
    </ThemeProvider>
  );
}
