import React, { useState, useEffect } from 'react';
import bannerImg from '../../../assets/img/Logo-Original-iLUMEN.png';

import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { theme_mainbanner } from './theme_mainbanner_ilumen';

const fontSize_H1 = {
    xs: '32px',
    sm: '32px',
    md: '50px',
    lg: '55px',
    xl: '60px',
};

const fontSize_P = {
    xs: '18px',
    sm: '15px',
    md: '20px',
    lg: '20px',
    xl: '20px',
};

const ResponsiveImage = ({ src, alt }) => {
    const [width, setWidth] = useState('80%');

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            let newWidth = '80%'; 

            if (screenWidth <= 575) {
                newWidth = '50%';
            } else if (screenWidth <= 767) {
                newWidth = '50%';
            } else if (screenWidth <= 991) {
                newWidth = '50%';
            } else if (screenWidth <= 1199) {
                newWidth = '80%';
            } else {
                newWidth = '60%';
            }

            setWidth(newWidth);
        };

        handleResize(); 

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <img src={src} alt={alt} style={{ width }} />;
};

export default function MainBanner_Ilumen() {
    return (
        <ThemeProvider theme={theme_mainbanner}>
            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                <Container maxWidth="xl">
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: 'center', marginBottom: { xs: '2rem', sm: 0 } }}>
                            <Typography variant="body1" component="p" sx={{ fontSize: fontSize_P }} id="inicio">
                                Conecta, gestiona y potencia asistencia escolar.
                            </Typography>

                            <Typography variant="h4" component="h1" sx={{ fontSize: fontSize_H1}}>
                                Tu aplicación móvil conectada a la administración estudiantil de manera integral
                            </Typography>

                            <Typography variant="body1" component="p" sx={{ fontSize: fontSize_P, textAlign: 'justify' }}>
                                Ilumen es la aplicación que te simplificará
                                la gestión de la <strong>asistencia</strong>, la <strong>deserción</strong>, la
                                <strong> aprobación</strong> y <strong>repitencia</strong> de todos los
                                estudiantes de la institución. <br />
                                Y, además, comunicará de forma
                                inmediata a directivos, cuerpo de
                                docentes y apoderados a través de modernas herramientas.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <ResponsiveImage src={bannerImg} alt="banner-image" />
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </ThemeProvider>
    );
}
