import { createTheme } from '@mui/material/styles';


export const theme_vista_web = createTheme({
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    background: '#f9ecfb', 
                    padding: '20px' ,
                    display:'flex'
                }
            }
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    background: 'inherit'
                }
            }
        }

    },

});

