import React, { useState } from 'react';
import { Container, Typography, Button, Grid, Paper, Avatar } from '@mui/material';
import { Link } from "react-router-dom";
import Box from '@mui/joy/Box';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';

import PublicIcon from '@mui/icons-material/Public';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import CreditCardIcon from '@mui/icons-material/CreditCard';


import img_sitioweb from '../../assets/images/sitio-web.png';
import img_votacion from '../../assets/images/votacion-web.png';
import img_comercio from '../../assets/images/comercio-web.png';
import icono_ilumen from '../../assets/img/Icono-iLUMEN-1.png';
import imagen_ilumen from '../../assets/img/ilumen-desktop-grafica-colegio.png';
import imagen_productos_educativos from '../../assets/img/img_productos_educativos.jpg'
import imagen_itotem from '../../assets/img/itotem.jpg'
import img_ilumen from '../../../src/assets/img/imgIlumen_khronolatam.png'


import { ThemeProvider, createTheme } from "@mui/material/styles";
import { theme_soluciones_vinculo_educativo } from './theme_soluciones_vinculo_educativo';

const tabColors = ['#008CFF !important', '#008CFF!important', '#008CFF !important', '#008CFF2 !important', '#008CFF!important'];

const tabStyles = {
  py: 1,
  flex: 1,
  transition: '0.3s',
  fontWeight: 'md',
  width: 'auto',
  height: 'auto',
  textAlign: 'center',
  borderRadius: '0.25rem',
  color: 'black',
};

const fontSize = {
  xs: '10px',
  sm: '15px',
  md: '20px',
  lg: '20px',
  xl: '20px',
};

const fontSize_H5 = {
  xs: '20px',
  sm: '20px',
  md: '25px',
  lg: '30px',
  xl: '30px',
};

const fontSize_Body1 = {
  xs: '15px',
  sm: '15px',
  md: '20px',
  lg: '20px',
  xl: '20px',
};

const fontSize_titulo = {
  xs: '20px',
  sm: '20px',
  md: '25px',
  lg: '28.8px',
  xl: '28.8px',
};

const fontSize_parrafo = {
  xs: '12px',
  sm: '12px',
  md: '18px',
  lg: '18px',
  xl: '18px',
};



const scrollToTop = () => {
  const scrollStep = -window.scrollY / (500 / 15); 
  const scrollInterval = setInterval(() => {
    if (window.scrollY !== 0) {
      window.scrollBy(0, scrollStep);
    } else {
      clearInterval(scrollInterval);
    }
  }, 15);
};




export default function SolucionesVinculoEducativo({empezarAhoraBotonRef}) {
  const [index, setIndex] = React.useState(0);


  const tabContents = [
    {
      title: 'iLumen',
      image: img_ilumen,
      text_titulo: 'Potencia la asistencia escolar de manera ordenada y de fácil acceso',
      text_parrafo: 'Facilitamos la gestión de la asistencia escolar de manera eficiente y comunicativa.',
    },
    {
      title: 'iTOTEM',
      image: imagen_itotem,
      text_titulo: 'Mejora la Comunicación con los apoderados y tutores, en el hall del colegio',
      text_parrafo: 'Solicita el iTOTEM y mantén informada a toda la comunidad educativa sobre asistencia, notas y eventos en tu colegio.',
    },
    {
      title: 'Productos Educativos',
      image: imagen_productos_educativos,
      text_titulo: 'Productos educativos tecnológicos y soluciones automatizadas para el aula y el colegio',
      text_parrafo: 'Productos para crear un aula interactiva y soluciones automatizadas para la seguridad, vigilancia, control de acceso e iluminación de manera remota, y otras herramientas domóticas',
    },

  ];


  return (
    <div>
      <Container maxWidth="xl" ref={empezarAhoraBotonRef} >
        <Typography variant="h5" sx={{ textAlign: 'center', fontSize: fontSize_H5, marginTop: '1rem', color: '#008CFF' }}>
          Múltiples soluciones para fortalecer el <span style={{ fontWeight: 'bold' }}>vínculo educativo y afectivo </span>
          de los <span style={{ fontWeight: 'bold' }}>apoderados y familia</span>,
          con los <span style={{ fontWeight: 'bold' }}>alumnos y el colegio.</span>
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'center', fontSize: fontSize_Body1, marginTop: '1rem', color: '#706F6F' }}>
          Soluciones inteligentes para fortalecer tu proyecto educativo
        </Typography>
        <br></br>
        <Box
          sx={{
            flexGrow: 1,
            m: 3,
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.2)',
          }}
        >

          <Tabs
            size="lg"
            value={index}
            onChange={(event, value) => setIndex(value)}
          >
            <TabList
              variant="plain"
              size="sm"
              disableUnderline
              sx={{ borderRadius: 'lg', p: 0 }}
            >

              {tabContents.map((tab, tabIndex) => (

                <Tab
                  key={tabIndex}
                  disableIndicator
                  orientation="vertical"
                  sx={{
                    ...tabStyles,
                    color: tabColors[tabIndex],
                    backgroundColor: tabIndex === 0 ? "#f76603" : tabIndex === 1 ? "#008cff" : tabIndex === 2 ? "#06894a" : "#fff",// Color por defecto si tabIndex no es 0
                    '&:hover': {
                      backgroundColor: tabIndex === 0 ? "#f76603" : tabIndex === 1 ? "#008cff" : tabIndex === 2 ? "#06894a" : "#fff", // Color por defecto si tabIndex no es 0
                    },
                    '&.Mui-selected': {
                      backgroundColor: tabIndex === 0 ? "#f76603" : tabIndex === 1 ? "#008cff" : tabIndex === 2 ? "#06894a" : "#fff", // Color por defecto si tabIndex no es 0
                    },
                    '&:hover:not(.Mui-selected)': {
                      backgroundColor: tabIndex === 0 ? "#f76603" : tabIndex === 1 ? "#008cff" : tabIndex === 2 ? "#06894a" : "#fff", // Color por defecto si tabIndex no es 0
                    },
                    border: '1px solid #ddd',
                  }}
                >
                  {tabIndex === 0 && <Avatar
                    alt="Logo"
                    src={icono_ilumen}
                    sx={{
                      width: { xs: 100, sm: 100, md: 90, lg: 90, xl: 90 },
                      height: 'auto',
                      borderRadius: 0,
                    }}
                  />}
                  {tabIndex === 1 && (
                    <Typography variant="body1" color="white">
                      iTOTEM
                    </Typography>
                  )}

                  {tabIndex === 2 && (
                    <Typography variant="body1" color="white">
                      Productos Educativos
                    </Typography>
                  )}
                </Tab>

              ))}

            </TabList>
          </Tabs>

        </Box>

        {tabContents.map((tab, tabIndex) => (
          <Grid container spacing={2} alignItems="center"
            key={tabIndex}
            style={{
              display: index === tabIndex ? 'flex' : 'none',
              padding: '20px',
              background: 'white',
            }}
          >

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: '20px', textAlign: 'left' }}>
              <Typography variant="p" sx={{ fontSize: fontSize_titulo, color: '#008CFF', fontWeight: '700', lineHeight: '1.5' }}>{tab.text_titulo}</Typography>
              <br></br><br></br>
              <Typography variant="p" sx={{ fontSize: fontSize_parrafo, color: '#706F6F' }}>{tab.text_parrafo}</Typography>
              <br></br><br></br>
              {tabIndex === 0 &&
                <Link to="/ilumen">
                  <Button variant="contained" href="#inicio" onClick={scrollToTop} sx={{
                    backgroundColor: '#ff5b00 !important',
                    borderColor: '#ff5b00 !important',
                    textTransform: 'none',

                  }}>
                    Ver más
                  </Button>
                </Link>
              }

              {tabIndex === 1 &&
                <Link >
                  <Button variant="contained" sx={{
                    backgroundColor: '#ff5b00!important',
                    borderColor: '#ff5b00 !important',
                    textTransform: 'none',
                  }}>
                    Ver más
                  </Button>
                </Link>
              }

              {tabIndex === 2 &&
                <Link >
                  <Button variant="contained" sx={{
                    backgroundColor: '#ff5b00 !important',
                    borderColor: '#ff5b00 !important',
                    textTransform: 'none',
                  }}>
                    Ver más
                  </Button>
                </Link>
              }

            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
              <img src={tab.image} alt={tab.text_titulo} width="100%" />
            </Grid>
          </Grid>
        ))}


      </Container>

    </div>

  );
}