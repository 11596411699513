import React, { useContext } from 'react';
import { Container, Button, Card, CardContent, Typography, Grid, Box, Divider } from '@mui/material';
import { AuthContext } from '../../context/AuthContext';
import { googleLogout } from '@react-oauth/google';

import FeedIcon from "@mui/icons-material/Feed";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CardDashboard from "./CardDashboard";

import MenuDashboardSec from "./MenuDashboardSec";

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const DashboardPage = () => {
  const { logout } = useContext(AuthContext);
  const handleLogout = () => {
    // Implementa la lógica de logout aquí
    console.log('Logout');
    googleLogout();
    logout();
  };

  const infoEncuestas = [];
  infoEncuestas.push(
    <Typography sx={{ fontSize: "12px", color: "#6C737F" }}>
      Cantidad de encuestados: xx
    </Typography>
  );
  const infoClientes = [];
  infoClientes.push(
    <Typography sx={{ fontSize: "12px", color: "#6C737F" }}>
      Cantidad de colegios: xx
    </Typography>
  );

  return (
    <>
   
    <Divider sx={{marginBottom:'40px'}}/>
      <MenuDashboardSec />

    </>
  );
};