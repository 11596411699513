import { createTheme } from '@mui/material/styles';

export const theme_footer = createTheme({
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                        textDecoration:'none'
                },
                body1: {
                   
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {

                },
                h6: {
                   color:'white'
                },
            }
        },

    }
});
