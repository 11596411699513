import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './context/AuthContext.js';

const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
    <React.StrictMode>{/* Utiliza React.StrictMode para verificar y advertir sobre prácticas inseguras */}
      <AuthProvider>
        <App />{/* Renderiza el componente AppRouter, que contiene la lógica principal de la aplicación */}
      </AuthProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
