import { Avatar, Grid, Typography } from '@mui/material'
import { Container, fontSize } from '@mui/system'
import React, { useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import icono_ilumen from '../../../assets/img/Icono-iLUMEN-1.png';
import Formulario_Ilumen from '../../Ilumen/Formulario_Ilumen/Formulario_Ilumen';
import { ComunicacionInternaProvider } from "../../../context/FormsContext";
import { DatosMiPerfilProvider } from "../../../context/MiPerfilContext";
import { AuthProvider } from "../../../context/AuthContext";



export default function AutenticadoMain() {

  const fontSize = {
    xs: '20px',
    sm: '20px',
    md: '25px',
    lg: '30px',
    xl: '30px',
  }



  return (
    <Container maxWidth="lg" >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ComunicacionInternaProvider>
            <DatosMiPerfilProvider>
              <AuthProvider>
                <Formulario_Ilumen />
              </AuthProvider>
            </DatosMiPerfilProvider>
          </ComunicacionInternaProvider>


        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>

        </Grid>
      </Grid>
    </Container>
  )
}
