import { createTheme } from '@mui/material/styles';


export const theme_registrar = createTheme({
    palette: {
        primary: {
            main: '#ff5b00',
        },
        secondary: {
            main: "#ff5b00",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform:'none'
                }
            }
        }

    },

});

