import { createTheme } from '@mui/material/styles';


export const theme_tipos_instituciones_educativas = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#706F6F',
                    fontWeight: '400',
                    letterSpacing: '1px',
                    marginTop: '5%'
                }
            }
        }

    },

});

