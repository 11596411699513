import { createTheme } from '@mui/material/styles';

const fontSize_H5 = {
    xs: '20px',
    sm: '20px',
    md: '25px',
    lg: '30px',
    xl: '30px',
};

const fontSize_Body1 = {
    xs: '15px',
    sm: '15px',
    md: '20px',
    lg: '20px',
    xl: '20px',
};

export const theme_trabajo_coordinado = createTheme({
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                },
            }
        },
        MuiTypography: {
            h5: {
                textAlign: 'center', fontSize: fontSize_H5, marginTop: '1rem', color: '#008CFF'
            },
            body1: {
                textAlign: 'center', fontSize: fontSize_Body1, marginTop: '1rem', color: '#706F6F'
            }
        }
    }
});
