import React from 'react';
import { Grid, Container, Typography, Paper, CssBaseline, Box, Link } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { theme_footer } from '../Footer/theme_footer';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import { Link as RouterLink } from 'react-router-dom';

export default function Footer() {
    const direccionCorreo = 'contacto@khronolatam.cl';
    const khronolatam_instagram = 'https://www.instagram.com/khronolatam/?igsh=NnBxMTRqN2lmcnds' ;

    const handleInstagramClick = () => {
        window.open(khronolatam_instagram, '_blank');
    };

    return (
        <>
            <ThemeProvider theme={theme_footer}>
                <Container
                    maxWidth={false}
                    sx={{
                        background: '#008CFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                        flexDirection: 'column',

                    }}
                >

                    <Grid container spacing={2} cols={15} sx={{ marginBottom: '25px' }}>
                        <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                            <Grid>
                                 <RouterLink onClick={handleInstagramClick} style={{ textDecoration: 'none' }}>
                                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: '80%', color: 'white', textDecoration: 'none', marginBottom: '10px' }}>
                                        <InstagramIcon sx={{ marginRight: '0.5rem' }} />
                                        @khronolatam.cl
                                    </Typography>
                                </RouterLink>
                            </Grid>
                            <Grid>
                                {/* SE ABRE EL CLIENTE DE CORREO DEL USUARIO */}
                                <a href={`mailto:${direccionCorreo}`} style={{ textDecoration: 'none' }}>
                                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: '80%', color: 'white', textDecoration: 'none', marginBottom: '10px' }}>
                                        <EmailIcon sx={{ marginRight: '0.5rem' }} />
                                        contacto@khronolatam.cl
                                    </Typography>
                                </a>
                            </Grid>
                            <Grid>
                                <RouterLink to='/' onClick={() => window.scrollTo(0, 0)} style={{ textDecoration: 'none' }}>
                                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', fontSize: '80%', color: 'white', textDecoration: 'none', marginBottom: '10px' }}>
                                        <LanguageIcon sx={{ marginRight: '0.5rem' }} />
                                        www.khronolatam.cl
                                    </Typography>
                                </RouterLink>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                            <RouterLink to="/ilumen" onClick={() => window.scrollTo(0, 0)} style={{ textDecoration: 'none' }}>
                                <Typography variant="h6" sx={{ fontSize: '0.8rem', fontWeight: '700', lineHeight: '1.1', textAlign: 'left', textDecoration: 'none' }}>
                                    iLumen Edutech
                                </Typography>
                            </RouterLink>
                            <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                                <li>
                                    <Link variant="body1" sx={{ fontSize: '80%', color: 'white', textDecoration: 'none' }}>
                                        Potencia la asistencia escolar de manera ordenada y de fácil acceso
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                            <Typography variant="h6" sx={{ fontSize: '0.8rem', fontWeight: '700', lineHeight: '1.1', textAlign: 'left' }}>iTotem Edutech</Typography>
                            <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                                <li>
                                    <Link href="#" variant="body1" sx={{ fontSize: '80%', color: 'white', textAlign: 'left', textDecoration: 'none' }}>
                                        Mejora la educación con los apoderados en el hall de tu colegio
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                            <Typography variant="h6" sx={{ fontSize: '0.8rem', fontWeight: '700', lineHeight: '1.1', textAlign: 'left' }}>Productos Educativos</Typography>
                            <ul style={{ listStyleType: 'none', padding: '0', textAlign: 'left' }}>
                                <li>
                                    <Link href="#" variant="body1" sx={{ fontSize: '80%', color: 'white', textAlign: 'left', textDecoration: 'none' }}>
                                        Pasa al siguiente nivel con aulas interactivas y domotica educativa
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider >
        </>
    );
}
