import React, { useState } from 'react';
import { Container, Typography, Button, Grid, Paper } from '@mui/material';
import Box from '@mui/joy/Box';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';

import DownloadIcon from '@mui/icons-material/Download';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CampaignIcon from '@mui/icons-material/Campaign';

import img_doc_firma from '../../assets/images/img_doc_firma.png';
import img_atencion_emergencias from '../../assets/images/img_atencion_emergencias.png';
import img_gestion_urbana from '../../assets/images/img_gestion_urbana.png';
import img_proyectos_tareas from '../../assets/images/img_proyectos_tareas.png';
import img_not_mensajes from '../../assets/images/img_notificaciones_mensajes.png';

import { ThemeProvider } from "@mui/material/styles";
import { theme_trabajo_coordinado } from './theme_trabajo_coordinado';

const tabColors = ['#008CFF !important', '#008CFF!important', '#008CFF!important', '#008CFF !important', '#008CFF!important'];

const tabStyles = {
    py: 1,
    flex: 1,
    transition: '0.3s',
    fontWeight: 'md',
    width: 'auto',
    height: 'auto',
    textAlign: 'center',
    borderRadius: '0.25rem',
    color: 'black',
    '&.Mui-selected': {
        color: '#008CFF !important',
    },
};


const fontSize = {
    xs: '10px',
    sm: '15px',
    md: '20px',
    lg: '20px',
    xl: '20px',
};

const fontSize_H5 = {
    xs: '20px',
    sm: '20px',
    md: '25px',
    lg: '30px',
    xl: '30px',
};

const fontSize_Body1 = {
    xs: '15px',
    sm: '15px',
    md: '20px',
    lg: '20px',
    xl: '20px',
};

const fontSize_titulo = {
    xs: '20px',
    sm: '20px',
    md: '25px',
    lg: '28.8px',
    xl: '28.8px',
};

const fontSize_parrafo = {
    xs: '12px',
    sm: '12px',
    md: '18px',
    lg: '18px',
    xl: '18px',
};

export default function TrabajoCoordinado() {
    const [index, setIndex] = React.useState(0);

    const tabContents = [
        {
            title: 'Documentos y Firma',
            image: img_doc_firma,
            text_titulo: 'Gestiona y firma documentos en una sola plataforma',
            text_parrafo: 'Podrás gestionar, firmar y compartir documentos de manera segura, tanto dentro como fuera de tu organización. Simplifica tus procesos y optimiza tu flujo de trabajo con nuestra herramienta todo en uno.',
        },
        {
            title: 'Atención y emergencias',
            image: img_atencion_emergencias,
            text_titulo: 'Atiende de forma remota a las personas de un lugar',
            text_parrafo: 'Transforma la atención al cliente con nuestro software de atención remota. Ofrecemos agendamiento inteligente, videollamadas, intercambio de documentos y gestión de procesos para brindar una experiencia personalizada y satisfactoria desde cualquier lugar.',
        },
        {
            title: 'Gestión Urbana',
            image: img_gestion_urbana,
            text_titulo: 'La gestión planificada de los elementos urbanos de tu ciudad',
            text_parrafo: 'Ayudamos a los gestores de la ciudad a mejorar la eficiencia y reducir los costos de operación a largo plazo, al permitirles programar el mantenimiento y las actualizaciones de manera proactiva en lugar.',
        },

    ];

    return (
        <div>
            <Container >
                <Typography variant="h5" sx={{ textAlign: 'center', fontSize: fontSize_H5, marginTop: '1rem', color: '#008CFF' }}>
                <span style={{ fontWeight: 'bold' }}>Conecta</span> a tu equipo para <span style={{ fontWeight: 'bold' }}>trabajar de manera coordinada</span>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'center', fontSize: fontSize_Body1, marginTop: '1rem', color: '#706F6F' }}>
                Con las opciones de Ilumen puedes escalar y dar agilidad a tus flujos de trabajo
                </Typography>
                <br></br>
            </Container>
        </div>
    );
}