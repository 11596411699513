import React, { useState, useContext, useEffect } from 'react';
import { ThemeProvider } from "@mui/material/styles";
import { theme_mi_perfil } from './theme_mi_perfil';
import { Box, Container, Grid, Avatar, Typography, Button, Divider, Hidden, TextField, DialogContentText } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { DatosMiPerfilContext } from "../../../context/MiPerfilContext";
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { fontSizeDialog } from '../../Utils/Utils';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function MiPerfil() {

    const { getObtenerUsuario, infoUsuario, modificarNombreUsuario, modificarRunUsuario, modificarTelefonoUsuario, guardarImagenUsuario,
        imagenUsuario, getObtenerImagenUsuario, eliminarImagenUsuario, setImagenUsuario, imagenSeleccionada, setImagenSeleccionada, estadoRefrescoMenuDashboard, setEstadoRefrescoMenuDashboard } = useContext(DatosMiPerfilContext);


    useEffect(() => {
        const fetchData = async () => {
            try {

                await getObtenerUsuario();
                await getObtenerImagenUsuario();


                //console.log('Ambas funciones se han ejecutado con éxito.');

            } catch (error) {
                console.error('Ocurrió un error al ejecutar las funciones:', error);
            }
        };

        fetchData();

    }, [imagenUsuario]);
    //console.log("refresca desde ,Miperfil useEffects #1");

    const [open, setOpen] = React.useState(false);
    const [openEditarNombre, setOpenEditarNombre] = React.useState(false);
    const [openNumeroTelefono, setOpenNumeroTelefono] = React.useState(false);
    const [openCorreoElectronico, setOpenCorreoElectronico] = React.useState(false);

    const [nombre, setNombre] = useState('');
    const [run, setRun] = useState('');
    const [phone, setPhone] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenEditarNombre = () => {
        setOpenEditarNombre(true);
    };

    const handleClickOpenNumeroTelefono = () => {
        setOpenNumeroTelefono(true);
    };

    const handleClickOpenCorreoElectronico = () => {
        setOpenCorreoElectronico(true);
    };


    const handleClose = () => {
        setOpen(false);
        setRun('');
    };

    const handleCloseEditarNombre = () => {
        setOpenEditarNombre(false);
        setNombre('');
    };

    const handleCloseNumeroTelefono = () => {
        setOpenNumeroTelefono(false);
        setPhone('');
    };

    const handleCloseCorreoElectronico = () => {
        setOpenCorreoElectronico(false);
    };

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }


    /* funciones para modificar nombre de usuario */
    const handleActualizarNombre = async () => {
        try {
            await modificarNombreUsuario(infoUsuario.id_usuario, nombre);
            await getObtenerUsuario(infoUsuario.nombre);
            setNombre('');
            handleCloseEditarNombre();

        } catch (error) {
            console.error('Error al modificar el nombre:', error);
        }
    };

    const handleGuardarActualizacionNombre = (e) => {
        const { value } = e.target;
        setNombre(value);
    };


    /* funciones para modificar rut de usuario */
    const handleActualizarRun = async () => {
        try {
            await modificarRunUsuario(infoUsuario.id_usuario, run);
            setRun('');
            await getObtenerUsuario(infoUsuario.run);
            handleClose()
        } catch (error) {
            console.error('Error al modificar el run:', error);
        }
    };

    const handleGuardarActualizacionRun = (e) => {
        const { value } = e.target;
        setRun(value);
    };

    /* funciones para modificar telefono de usuario */
    const handleActualizarTelefono = async () => {
        try {
            await modificarTelefonoUsuario(infoUsuario.id_usuario, phone);
            setPhone('');
            await getObtenerUsuario(infoUsuario.phone);
            handleCloseNumeroTelefono()
        } catch (error) {
            console.error('Error al modificar el run:', error);
        }
    };

    const handleGuardarActualizacionTelefono = (e) => {
        const { value } = e.target;
        setPhone(value);
    };


    /* Funciones para agregar imagen de perfil */


    const handleImagenSeleccionada = (event) => {
        const file = event.target.files[0];
        //console.log(file)
        if (file) {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async () => {
                    const base64Image = reader.result;
                    //setImagenSeleccionada(base64Image);
                    await guardarImagenUsuario(base64Image);
                    await getObtenerImagenUsuario();
                    //setEstadoRefrescoMenuDashboard(false);

                };
                reader.onerror = (error) => {
                    console.error('Error al leer la imagen:', error);
                };
            } catch (error) {
                console.error('Error al convertir la imagen a base64:', error);
            }
        }
    };


    const [id_usuario, setIdUsuario] = useState('');

    const handleEliminarImagenUsuario = async (id_usuario) => {
        try {
            //console.log(id_usuario)
            await eliminarImagenUsuario(id_usuario);
            await getObtenerImagenUsuario();
            //setEstadoRefrescoMenuDashboard(false);
        } catch (error) {
            console.error('Error al eliminar la imagen de perfil :', error);

        }
    };



    //console.log(imagenUsuario)

    return (
        <ThemeProvider theme={theme_mi_perfil}>
            <Container maxWidth="lg">
                <div role="presentation" onClick={handleClick}>
                    <Breadcrumbs aria-label="breadcrumb">
                        {/*  <Link underline="hover" color="inherit" href="/">
                            MUI
                        </Link> */}
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard"
                            style={{ color: '#706F6F', textDecoration: 'none', fontWeight: 'normal' }}
                        >
                            Formulario
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="/mi_perfil"
                            aria-current="page"
                            style={{ color: '#706F6F', textDecoration: 'none', fontWeight: 'normal' }}
                        >
                            Mi perfil
                        </Link>
                    </Breadcrumbs>
                </div>
                <Box sx={{ padding: 2, border: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'white' }} borderRadius={2}>
                    <Box >
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '6px' }} >
                                <Avatar sx={{ width: '110px', height: '110px' }}>
                                    {imagenUsuario === null ? (
                                        <PersonIcon style={{ width: '70%', height: '70%' }} />
                                    ) : (
                                        <img src={imagenUsuario} style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                                    )}
                                </Avatar>

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px' }}>
                                <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                    Personaliza tu cuenta con una foto. La foto de perfil aparecerá en las aplicaciones y dispositivos que usan tu cuenta de Khronolatam.
                                </Typography>

                                <input
                                    type="file"
                                    id="imagenInput"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImagenSeleccionada}
                                />
                                <label htmlFor="imagenInput">
                                    <Button variant="outlined" sx={{ marginTop: '6px' }} component="span" size='small'>
                                        <AddIcon style={{ color: '#1f7b3ab5' }} />
                                        &nbsp;Agregar una foto
                                    </Button>
                                </label>

                                <Button variant="outlined" sx={{ marginTop: '6px' }} component="span" size='small' onClick={() => handleEliminarImagenUsuario(id_usuario)}>
                                    <DeleteIcon style={{ color: '#f1948d' }} />
                                    &nbsp;Eliminar foto
                                </Button>
                            </Grid>


                        </Grid>
                        <Divider style={{ marginTop: '4px' }} />
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                                    Nombre Completo
                                </Typography>
                            </Grid>

                            <Grid item sm={3} md={3} lg={3} xl={3}>
                                <Typography variant="body2" sx={{ textAlign: 'justify', fontWeight: 'bold', color: 'black' }}>
                                    {infoUsuario.nombre}
                                </Typography>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3} xl={3} sx={{ display: 'flex', justifyContent: 'initial', alignItems: 'center' }}>

                            </Grid>

                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                                <Button onClick={handleClickOpenEditarNombre} sx={{ color: '#008CFF', textAlign: 'end' }}>
                                    Editar nombre
                                </Button>
                            </Grid>

                            <React.Fragment>
                                <Dialog
                                    onClose={handleCloseEditarNombre}
                                    open={openEditarNombre}
                                >
                                    <DialogTitle sx={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'left' }}>
                                        Editar nombre
                                    </DialogTitle>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleCloseEditarNombre}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>

                                    <DialogContent>
                                        <Typography component="div" sx={{ fontSize: fontSizeDialog }}>
                                            Puedes editar tu nombre de cuenta. Simplemente introduce el nuevo nombre en el campo proporcionado y
                                            haz clic en "Guardar". ¡Así de fácil!
                                        </Typography>

                                        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Typography component="div" sx={{ fontSize: fontSizeDialog, fontWeight: "bold" }} >
                                                    Nombre
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                                                <TextField
                                                    autoFocus
                                                    required
                                                    margin="dense"
                                                    variant="standard"
                                                    fullWidth
                                                    size="small"
                                                    type='text'
                                                    value={nombre}
                                                    onChange={handleGuardarActualizacionNombre}
                                                />
                                            </Grid>
                                        </Grid>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleActualizarNombre} variant="contained" color="success">
                                            Guardar
                                        </Button>
                                        <Button onClick={handleCloseEditarNombre} variant="contained" color="error">
                                            Cancelar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </React.Fragment>
                        </Grid>
                    </Box>
                </Box>



                <Box sx={{ padding: 2, border: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'white', marginTop: '16px' }} borderRadius={2}>
                    <Box>
                        <Grid item container spacing={2} xs={12} sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px', marginBottom: '7px', marginTop: '-30px' }}>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px' }}>
                                <Typography variant="body2" sx={{ textAlign: 'justify', fontWeight: 'bold', color: 'black' }}>
                                    Información de perfil
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                                <Button sx={{ color: '#008CFF', textAlign: 'end' }}>
                                    Editar informaci&oacute;n de perfil
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginBottom: '4px' }} />

                        {/* RUT */}
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body2" sx={{ textAlign: 'justify', fontSize: '14px' }}>
                                    Rut
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <Typography variant="body2" sx={{ textAlign: 'justify', fontWeight: 'bold', color: 'black' }}>
                                    {infoUsuario.run}
                                </Typography>

                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'initial', alignItems: 'center' }}>
                                    <Typography variant="body2" sx={{ textAlign: 'initial', fontSize: '14px', paddingRight: '10px', display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        Tu Rut se usa para seguridad de la cuenta.
                                    </Typography>
                                    {/* <IconButton onClick={handleClickOpen} style={{ color: '#706F6F' }}>
                                        <ArrowForwardIosIcon style={{ fontSize: '14px', textAlign: 'end', display: { xs: 'none', sm: 'none', md: 'bloc' } }} />
                                    </IconButton> */}

                                </Grid>
                                <Grid item xs={2} md={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                    <IconButton onClick={handleClickOpen} style={{ color: '#706F6F' }}>
                                        <ArrowForwardIosIcon style={{ fontSize: '14px', textAlign: 'end', display: { xs: 'none', sm: 'none', md: 'bloc' } }} />
                                    </IconButton>

                                </Grid>
                            </Hidden>

                            <Hidden smUp>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                    <IconButton onClick={handleClickOpen} style={{ color: '#706F6F' }}>
                                        <ArrowForwardIosIcon style={{ fontSize: '14px', textAlign: 'right', marginLeft: '100px' }} />
                                    </IconButton>
                                </Grid>
                            </Hidden>

                            <React.Fragment>
                                <BootstrapDialog
                                    onClose={handleClose}
                                    open={open}
                                >
                                    <DialogTitle sx={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'left' }}>
                                        Editar información de perfil
                                    </DialogTitle>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <DialogContent >
                                        <Typography component="div" sx={{ fontSize: fontSizeDialog }}>
                                            Si cometiste algún error en tu rut, ¡no te preocupes! Puedes corregirlo fácilmente introduciendo el nuevo rut
                                            en el campo proporcionado y luego haciendo clic en 'Guardar'. ¡Es así de simple!
                                        </Typography>
                                        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Typography component="div" sx={{ fontSize: fontSizeDialog, fontWeight: "bold" }}>
                                                    Rut
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                                                <TextField
                                                    autoFocus
                                                    required
                                                    margin="dense"
                                                    variant="standard"
                                                    fullWidth
                                                    size="small"
                                                    type='text'
                                                    value={run}
                                                    onChange={handleGuardarActualizacionRun}

                                                />
                                            </Grid>
                                        </Grid>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleActualizarRun} variant="contained" color="success">
                                            Guardar
                                        </Button>
                                        <Button onClick={handleClose} variant="contained" color="error">
                                            Cancelar

                                        </Button>
                                    </DialogActions>
                                </BootstrapDialog>
                            </React.Fragment>
                        </Grid>

                        {/* NUMERO DE TELEFONO */}
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body2" sx={{ textAlign: 'justify', fontSize: '14px' }}>
                                    N&uacute;mero de Telef&oacute;no
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <Typography variant="body2" sx={{ textAlign: 'justify', fontWeight: 'bold', color: 'black' }}>
                                    {infoUsuario.phone}
                                </Typography>

                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={4} md={4} sx={{ display: 'flex', justifyContent: 'initial', alignItems: 'center' }}>
                                    <Typography variant="body2" sx={{ textAlign: 'initial', fontSize: '14px', paddingRight: '10px', display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        El número de teléfono contribuye a mejoras y beneficios futuros del servicio.
                                    </Typography>
                                    {/*  <IconButton onClick={handleClickOpenNumeroTelefono} style={{ color: '#706F6F' }}>
                                        <ArrowForwardIosIcon style={{ fontSize: '14px', textAlign: 'center', display: { xs: 'none', sm: 'none', md: 'bloc' } }} />
                                    </IconButton> */}
                                </Grid>

                                <Grid item xs={2} md={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                    <IconButton onClick={handleClickOpenNumeroTelefono} style={{ color: '#706F6F' }}>
                                        <ArrowForwardIosIcon style={{ fontSize: '14px', textAlign: 'end', display: { xs: 'none', sm: 'none', md: 'bloc' } }} />
                                    </IconButton>
                                </Grid>
                            </Hidden>

                            <Hidden smUp>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                    <IconButton onClick={handleClickOpenNumeroTelefono} style={{ color: '#706F6F' }}>
                                        <ArrowForwardIosIcon style={{ fontSize: '14px', textAlign: 'right', marginLeft: '100px' }} />
                                    </IconButton>
                                </Grid>
                            </Hidden>

                            <React.Fragment>
                                <BootstrapDialog
                                    onClose={handleCloseNumeroTelefono}
                                    open={openNumeroTelefono}
                                >
                                    <DialogTitle sx={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'left' }}>
                                        Editar información de perfil
                                    </DialogTitle>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleCloseNumeroTelefono}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <DialogContent>
                                        <Typography component="div" sx={{ fontSize: fontSizeDialog }}>
                                            Si necesitas corregir tu número de teléfono, no te preocupes. Puedes hacerlo fácilmente ingresando el nuevo número en el campo provisto y
                                            luego haciendo clic en 'Guardar'. ¡Es realmente sencillo!
                                        </Typography>
                                        <Grid container spacing={2} sx={{ marginTop: '5px' }} >
                                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Typography component="div" sx={{ fontSize: fontSizeDialog, fontWeight: "bold" }}>
                                                    N&uacute;mero de Telef&oacute;no
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                                                <TextField
                                                    autoFocus
                                                    required
                                                    margin="dense"
                                                    variant="standard"
                                                    fullWidth
                                                    size="small"
                                                    type='tel'
                                                    value={phone}
                                                    onChange={handleGuardarActualizacionTelefono}
                                                />
                                            </Grid>
                                        </Grid>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleActualizarTelefono} variant="contained" color="success">
                                            Guardar
                                        </Button>
                                        <Button onClick={handleCloseNumeroTelefono} variant="contained" color="error">
                                            Cancelar
                                        </Button>
                                    </DialogActions>
                                </BootstrapDialog>
                            </React.Fragment>
                        </Grid>


                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body2" sx={{ textAlign: 'justify', fontSize: '14px' }}>
                                    Correo Electr&oacute;nico
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <Typography variant="body2" sx={{ textAlign: 'justify', fontWeight: 'bold', color: 'black' }}>
                                    {infoUsuario.email}

                                </Typography>

                            </Grid>
                            {/* <Hidden smDown> */}
                            <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'initial', alignItems: 'center' }}>
                                <Typography variant="body2" sx={{ textAlign: 'initial', fontSize: '14px', paddingRight: '10px', display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                    Correo electrónico de inicio de sesión en Khronolatam.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container >
        </ThemeProvider >
    )
}
